import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";

export const necoCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			reclamationsEnabled: true,
			configuratorEnabled: true,
			defaultCustomerType: "COMPANY",
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
};
