// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalvosCustomerOrderApi {
	export async function setDeliveryDateAgreedDate(args: {
		customerOrderId: number;
		deliveryDateAgreedDate: IsoDateString | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/setDeliveryDateAgreedDate",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				deliveryDateAgreedDate: args.deliveryDateAgreedDate,
			},
		});
		return response as void;
	}

	export async function setCustomerOrderBuildingLicenceDate(args: {
		customerOrderId: number;
		buildingLicenceDate: IsoDateString | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/setCustomerOrderBuildingLicenceDate",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				buildingLicenceDate: args.buildingLicenceDate,
			},
		});
		return response as void;
	}

	export async function markCustomerOrderToCompleted(args: {
		customerOrderId: number;
		sendCustomerSatisfactionSurvey: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/markCustomerOrderToCompleted",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				sendCustomerSatisfactionSurvey: args.sendCustomerSatisfactionSurvey,
			},
		});
		return response as void;
	}
}
