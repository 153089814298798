import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { ConnectSalesPartsToPriceListViewApi } from "src/api/generated/erp/sales/pricing/api/connectSalesPartsToPriceListViewApi.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import i18n from "i18next";
import { floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink, faPercent } from "@fortawesome/pro-regular-svg-icons";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { SitesApi } from "src/api/generated/erp/common/sites/sitesApi.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView.ts";
import { CrudDataGridApi } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { useRef } from "react";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import dayjs from "dayjs";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { SiteView } from "src/api/generated/erp/db/types/tables/siteView.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";

export interface ConnectSalesPartsToPriceListViewProps {
	salesPriceListId: number;
	onCompleted: () => void;
}

export const ConnectSalesPartsToPriceListView = ({
	salesPriceListId,
	onCompleted,
}: ConnectSalesPartsToPriceListViewProps) => {
	const { openDialog } = useGenericDialog();
	const showInputDialog = useInputDialog();

	const { dataGridProps, refreshData, selectedRows } = useServerSideDataGridModel({
		fetchData: (params) =>
			ConnectSalesPartsToPriceListViewApi.getConnectableSalesParts({
				salesPriceListId: salesPriceListId,
				...params,
			}),
		initialParams: {
			siteIdFilter: genericNullableValue<number>(),
			searchQuery: "",
		},
		getRowId: (row) => row.salesPartId,
		gridId: "6A6E851DF4A8DC2B",
	});

	return (
		<ControlledAsyncCrudDataGrid<SalesPartView>
			columns={commonSalesPartColumns}
			checkboxSelection
			actionBarComponents={
				<>
					<LazySelectField
						label={i18n.t("site")}
						fetchOptions={() => SitesApi.getAll()}
						getOptionKey={(option: SiteView) => option.siteId}
						getOptionLabel={(option) => option.siteName}
						onChange={(value) => refreshData({ siteIdFilter: value })}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(value) => refreshData({ searchQuery: value })}
					/>
					<AsyncButton
						label={i18n.t("connect_selected")}
						icon={faLink}
						variant={"contained"}
						disabled={selectedRows.length === 0}
						onClick={async () => {
							const activeFrom = await showInputDialog({
								type: "date",
								title: i18n.t("active_from"),
								required: true,
								defaultValue: dayjs().local(),
							});
							if (activeFrom == undefined) return;

							openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
								title: i18n.t("set_prices"),
								size: "xl",
								content: (
									<SetPricesForSelectedPartsView
										salesParts={selectedRows}
										defaultActiveFrom={dayJsToDateIsoString(activeFrom)}
										onCompleted={(rows) => onSetOptionsViewCompleted(rows, closeDialog)}
										dataDirtyStateChanged={onDataDirtyStateChanged}
									/>
								),
							}));
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);

	async function onSetOptionsViewCompleted(
		rowsToSave: SetPricesViewRow[],
		closeSetPricesDialog: () => void,
	) {
		await ConnectSalesPartsToPriceListViewApi.addLinesToPriceList({
			salesPriceListId: salesPriceListId,
			lines: rowsToSave.map((row) => ({
				salesPartId: row.salesPartId,
				activeFrom: row.activeFrom,
				price: row.price ?? 0,
			})),
		});
		closeSetPricesDialog();
		onCompleted();
	}
};

interface SetPricesForSelectedPartsViewProps {
	salesParts: SalesPartView[];
	defaultActiveFrom: IsoDateString;
	dataDirtyStateChanged: DataDirtyStateChangeHandler;
	onCompleted: (updatedRows: SetPricesViewRow[]) => Promise<unknown>;
}

interface SetPricesViewRow extends SalesPartView {
	price: number | undefined;
	activeFrom: IsoDateString;
}

const SetPricesForSelectedPartsView = ({
	salesParts,
	defaultActiveFrom,
	onCompleted,
	dataDirtyStateChanged,
}: SetPricesForSelectedPartsViewProps) => {
	const crudDataGridApiRef = useRef<CrudDataGridApi | null>(null);
	const showInputDialog = useInputDialog();

	const rowsInitial: SetPricesViewRow[] = salesParts.map((row) => ({
		...row,
		price: undefined,
		activeFrom: defaultActiveFrom,
	}));

	const { dataGridProps, selectedRows } = useClientSideDataGridModel<SetPricesViewRow, object>({
		fetchData: async () => rowsInitial,
		initialParams: {},
		defaultSelectedRows: rowsInitial,
		gridId: "9A7BCBEB73A87B18",
		getRowId: (row) => row.salesPartId,
	});

	return (
		<ControlledAsyncCrudDataGrid<SetPricesViewRow>
			crudDataGridApiRef={crudDataGridApiRef}
			checkboxSelection
			dataDirtyStateChanged={dataDirtyStateChanged}
			columns={[
				...(commonSalesPartColumns as GridColDef<SetPricesViewRow>[]),
				floatColumn({
					field: "price",
					headerName: i18n.t("price"),
					width: 80,
					editable: true,
					validate: "required",
				}),
			]}
			save={async ({ items }) => {
				await onCompleted(items);
				return items;
			}}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("set_margin_for_selected")}
						icon={faPercent}
						variant={"outlined"}
						disabled={selectedRows.length === 0}
						onClick={async () => {
							const margin = await showInputDialog({
								type: "decimal",
								title: i18n.t("set_margin.sales"),
								fieldLabel: i18n.t("margin.sales") + " %",
								defaultValue: null,
								required: true,
							});
							if (margin == undefined || margin <= 0 || margin >= 100) return;

							crudDataGridApiRef.current?.updateRows(
								selectedRows.map((row) => {
									const cost = row.standardCostAsPriceUnits ?? 0;
									return {
										...row,
										price: cost / (1 - margin / 100),
									};
								}),
							);
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};

const commonSalesPartColumns: GridColDef<SalesPartView>[] = [
	textColumn({
		field: "siteName",
		headerName: i18n.t("site"),
	}),
	textColumn({
		field: "salesPartNo",
		headerName: i18n.t("sales_part_no"),
		width: 130,
	}),
	textColumn({
		field: "partDescription_1",
		headerName: i18n.t("description_1"),
		width: 250,
	}),
	textColumn({
		field: "partDescription_2",
		headerName: i18n.t("description_2"),
		width: 250,
	}),
	floatColumn({
		field: "standardCostAsPriceUnits",
		headerName: i18n.t("cost"),
		width: 100,
	}),
	textColumn({
		field: "salesPriceUnit",
		headerName: i18n.t("price_unit"),
		width: 120,
	}),
];
