import {
    SpcSamplingMethod
} from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";
import {FormMultiSelectField} from "src/components/common/forms/fields/FormMultiSelectField.tsx";
import {FormLazySelectField} from "src/components/common/forms/fields/FormLazySelectField.tsx";
import {SitesApi} from "src/api/generated/erp/common/sites/sitesApi.ts";
import {requireRule} from "src/components/common/forms/validation.ts";
import {toIntOrNull} from "src/utils/strings.tsx";
import {
    getInventoryLevelEventTypeLabel,
    InventoryLevelEventTypeValues,
} from "src/api/generated/erp/db/types/enums/inventoryLevelEventType.ts";
import {SitePartQueryApi} from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {FetchAsyncOptionParams} from "src/components/common/inputFields/AsyncSelectField.tsx";

export const getInventoryLevelEventQuantitySpcSamplingMethod = (): SpcSamplingMethod => {
	return {
		key: "erp_inventory_level_event_quantity",
		label: i18n.t("spc_sampling_method.inventory_level_event_quantity"),
		renderParameterFields: ({ control, watch, setValue }) => {
			const siteId = toIntOrNull(watch("samplingParams.param1"));

			return (
				<>
					<FormLazySelectField
						control={control}
						name={"samplingParams.param1"}
						label={i18n.t("site")}
						fetchOptions={SitesApi.getAll}
						getOptionKey={(o) => o.siteId.toString()}
						getOptionLabel={(o) => o.siteName}
						rules={requireRule()}
						onSubmit={() => setValue("samplingParams.param2", null)}
					/>
					{siteId != null && (
						<FormAsyncSelectField
							control={control}
							name={"samplingParams.param2"}
							label={i18n.t("part")}
							fetchOptions={({ searchQuery, currentSelection }: FetchAsyncOptionParams<string>) => {
								return SitePartQueryApi.getPartSelectionOptions({
									siteId: siteId,
									searchQuery: searchQuery,
									currentPartId: currentSelection != null ? parseInt(currentSelection) : null,
								});
							}}
							getOptionKey={(o) => o.partId.toString()}
							getOptionLabel={(o) => o.partLongName}
						/>
					)}
					<FormMultiSelectField
						control={control}
						name={"samplingParams.param3"}
						label={i18n.t("inventory_level_event_type")}
						options={InventoryLevelEventTypeValues.map((state) => ({
							key: state,
							label: getInventoryLevelEventTypeLabel(state),
						}))}
						rules={requireRule()}
						encodeValue={(v) => JSON.stringify(v)}
						decodeValue={(v) => (v != null ? JSON.parse(v) : [])}
					/>
				</>
			);
		},
	};
};
