import { ControlChartSelectionApi } from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { ControlChartParentGroupEditApi } from "src/api/generated/spc/controlChart/api/controlChartParentGroupEditApi.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";

export interface ControlChartParentGroupDataGridProps {
	setSelectedParentGroup: (parentGroup: ControlChartParentGroup | undefined) => void;
}

export const ControlChartParentGroupDataGrid = ({
	setSelectedParentGroup,
}: ControlChartParentGroupDataGridProps) => {
	const { onlySelectedRow, refreshData, dataGridProps } = useClientSideDataGridModel({
		gridId: "8A09637BD2C338AB",
		fetchData: (props) => ControlChartSelectionApi.getControlChartParentGroups({ ...props }),
		getRowId: (row) => row.id,
		initialParams: { searchQuery: "" },
		onSelectionChanged: (selectedRows) => {
			setSelectedParentGroup(selectedRows[0]);
		},
	});
	return (
		<ControlledAsyncCrudDataGrid<ControlChartParentGroup>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					editable: true,
					width: 250,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					editable: true,
				}),
				integerColumn({
					field: "id",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("CONTROL_CHART_PARENT_GROUP", onlySelectedRow?.id)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (value) => {
							await refreshData({ searchQuery: value });
						}}
					/>
				</>
			}
			add={async () => {
				return {
					name: "",
					description: "",
				};
			}}
			save={async ({ items }) =>
				await ControlChartParentGroupEditApi.save({
					parentGroups: items,
				})
			}
			remove={async ({ items }) =>
				await ControlChartParentGroupEditApi.delete_({
					parentGroups: items,
				})
			}
			{...dataGridProps}
		/>
	);
};
