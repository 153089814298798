import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorView } from "../PartConfiguratorView";
import { PartConfiguratorFormResult } from "../PartConfiguratorForm";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import { PartConfigurationInspectingView } from "../../inspecting/PartConfigurationInspectingView";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import {
	askObjectConfigurationHistoryEntryInput
} from "src/components/views/erp/configurator/objectConfigurationHistory/ObjectConfigurationHistoryEntryInputForm.utils.tsx";

export interface ShopOrderLineConfiguratorViewProps {
	shopOrderLineId: number;
	closeDialog: () => void;
	refreshSourceView: () => void;
}

export const ShopOrderLineConfiguratorView = ({
	shopOrderLineId,
	closeDialog,
	refreshSourceView,
}: ShopOrderLineConfiguratorViewProps) => {
	const { openDialog } = useGenericDialog();

	const configuratorType: PartConfiguratorType = {
		type: "shopOrderLine",
		shopOrderLineId: shopOrderLineId,
		isTestConfigurator: false,
	};

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					await onSubmit(result.value);
				} else {
					closeDialog?.();
				}
			}}
		/>
	);

	async function onSubmit({
		propertyValues,
		configurationSessionId,
	}: PartConfiguratorFormResult) {
		const historyEntryInput = await askObjectConfigurationHistoryEntryInput({
			openDialog,
			objectType: "SHOP_ORDER_LINE",
			objectId: shopOrderLineId,
		});
		if (historyEntryInput === undefined) return;

		const partConfigurationId =
			await ConfigurationCreationApi.createPartConfigurationForShopOrderLine({
				shopOrderLineId: shopOrderLineId,
				propertyValues: propertyValues,
				configurationSessionId: configurationSessionId,
				historyEntryInput: historyEntryInput,
			});

		closeDialog();
		refreshSourceView();

		openDialog(() => ({
			title: i18n.t("configuration"),
			content: <PartConfigurationInspectingView partConfigurationId={partConfigurationId} />,
		}));
	}
};
