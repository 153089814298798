import { LegacyModalViewDefinition, ViewAssociation } from "./types";
import { v4 as uuidv4 } from "uuid";
import {
	isCloseFloatingViewEvent,
	isCloseUppermostFloatingViewEvent,
	isOpenDynamicViewEvent,
	isOpenFloatingViewEvent,
	isOpenFrontendModalViewEvent,
	isRefreshSourceViewEvent,
	OPEN_FLOATING_VIEW_ACTION_SPECIFIER,
	VersionedViewActionEventList,
	ViewActionEvent,
} from "./events";
import {
	getDynamicViewDefinitionFromEvent,
	getModalViewParamsFromEvent,
	getViewOrErrorFromStartUpData,
	isErrorViewDefinition,
} from "src/components/views/legacy/utils";
import { StartUpData } from "src/api/mainApi";
import React from "react";
import { KeyValuePair } from "src/utils/keyValuePair.ts";
import { ModalFrontendViewConfig } from "src/components/views/frontendViews/frontendViewConfig.ts";
import { FRONTEND_VIEWS_BY_KEYS } from "src/components/views/frontendViews/frontendViews.ts";
import dayjs, { Dayjs } from "dayjs";

interface UUIDField {
	uuid: string;
}

interface LegacyModalViewState extends Omit<LegacyModalViewDefinition, "isUppermostView"> {
	view: ViewAssociation;
	uuid: string;
	refreshSourceView?: () => void;
}

export type AavoViewsContextState = {
	viewIdsOpened: string[];
	frontendViewsOpened: {
		creationTime: Dayjs;
		viewKey: string;
	}[];
	legacyModals: Array<LegacyModalViewState>;
	frontendViewModals: Array<FrontendViewModal>;
	test?: string;
	viewActionEvents: VersionedViewActionEventList;
	dragDropData: any;
};

export interface FrontendViewModal extends ModalFrontendViewConfig<unknown> {
	uuid: string;
	viewParams: KeyValuePair[];
	sourceViewId: string | null;
}

const AAVO_VIEWS_CONTEXT_DEFAULT: AavoViewsContextState = {
	viewIdsOpened: [],
	frontendViewsOpened: [],
	frontendViewModals: [],
	viewActionEvents: {
		version: 0,
		events: [],
	},
	legacyModals: [],
	dragDropData: undefined,
};

export type Action =
	| {
			type: "addActiveViewId";
			viewId: string;
	  }
	| {
			type: "addActiveFrontendView";
			viewKey: string;
	  }
	| {
			type: "setViewActionEvents";
			events: Array<ViewActionEvent>;
			startUpData: StartUpData;
	  }
	| {
			type: "openLegacyModal";
			modalViewState: Omit<LegacyModalViewState, "uuid">;
	  }
	| {
			type: "closeLegacyModalWithUuid";
			uuid: string;
	  }
	| {
			type: "setLegacyModalWithUuidUppermost";
			uuid: string;
	  }
	| {
			type: "closeLegacyModalsWithId";
			viewId: string;
	  }
	| {
			type: "closeUppermostLegacyModal";
	  }
	| {
			type: "setDragDropData";
			dragDropData: any;
	  }
	| {
			type: "openFrontendModal";
			viewActionEvent: ViewActionEvent;
	  }
	| {
			type: "closeFrontendModalWithUuid";
			uuid: string;
	  };

type Dispatch = (action: Action) => void;

export const AavoViewsContext = React.createContext<
	| {
			state: AavoViewsContextState;
			dispatch: Dispatch;
	  }
	| undefined
>(undefined);

export const AavoViewsDispatchContext = React.createContext<Dispatch | undefined>(undefined);

type AavoViewsProviderProps = {
	children: React.ReactNode;
};

export function AavoViewsProvider({ children }: AavoViewsProviderProps) {
	const [state, dispatch] = React.useReducer(aavoViewsReducer, AAVO_VIEWS_CONTEXT_DEFAULT);
	const value = { state, dispatch };
	return (
		<AavoViewsContext.Provider value={value}>
			<AavoViewsDispatchContext.Provider value={dispatch}>{children}</AavoViewsDispatchContext.Provider>
		</AavoViewsContext.Provider>
	);
}

type AavoViewsReducerFunction = (state: AavoViewsContextState, action: Action) => AavoViewsContextState;

const aavoViewsReducer: AavoViewsReducerFunction = (state, action) => {
	switch (action.type) {
		case "closeFrontendModalWithUuid":
			return {
				...state,
				frontendViewModals: excludeItemWithUuid(action.uuid, state.frontendViewModals),
			};
		case "openFrontendModal":
			return handleOpenFrontendModal(action.viewActionEvent, state);
		case "setDragDropData":
			return {
				...state,
				dragDropData: action.dragDropData,
			};
		case "closeUppermostLegacyModal":
			return {
				...state,
				legacyModals: state.legacyModals.slice(1),
			};
		case "closeLegacyModalsWithId":
			return {
				...state,
				legacyModals: excludeModalsWithViewId(action.viewId, state.legacyModals),
			};
		case "openLegacyModal":
			return {
				...state,
				legacyModals: [{ ...action.modalViewState, uuid: uuidv4() }, ...state.legacyModals],
			};
		case "closeLegacyModalWithUuid":
			return {
				...state,
				legacyModals: excludeItemWithUuid(action.uuid, state.legacyModals),
			};
		case "setLegacyModalWithUuidUppermost":
			return {
				...state,
				legacyModals: modalToUppermost(action.uuid, state.legacyModals),
			};
		case "setViewActionEvents":
			return handleSetActionViewEvents(action.events, action.startUpData, state);
		case "addActiveViewId":
			return state.viewIdsOpened.includes(action.viewId) ?
					state
				:	{
						...state,
						viewIdsOpened: [action.viewId, ...state.viewIdsOpened],
					};
		case "addActiveFrontendView": {
			return addActiveFrontendView(state, action.viewKey);
		}
	}
};

/**
 * Keeps defined count of frontend views in the state. If the count exceeds, the oldest view is replaced.
 * Position of views in the state are preserved on their original position to prevent
 * re-rendering of the views.
 */
function addActiveFrontendView(state: AavoViewsContextState, viewKey: string): AavoViewsContextState {
	const viewsToKeepCount = 8;

	const currentIndex = state.frontendViewsOpened.findIndex((view) => view.viewKey === viewKey);
	if (currentIndex != -1) {
		return replaceViewAtIndex(currentIndex);
	}

	const shouldRemoveSomeView = state.frontendViewsOpened.length >= viewsToKeepCount;
	if (shouldRemoveSomeView) {
		const viewToReplaceIndex = findOldestFrontendViewIndex(state);
		return replaceViewAtIndex(viewToReplaceIndex);
	} else {
		return replaceViewAtIndex(state.frontendViewsOpened.length);
	}

	function replaceViewAtIndex(index: number) {
		const newFrontendViewsOpened = state.frontendViewsOpened.slice();
		newFrontendViewsOpened[index] = {
			creationTime: dayjs(),
			viewKey: viewKey,
		};
		return {
			...state,
			frontendViewsOpened: newFrontendViewsOpened,
		};
	}

	function findOldestFrontendViewIndex(state: AavoViewsContextState): number {
		interface ReduceAccumulator {
			earliestCreationTime: Dayjs | null;
			index: number;
		}

		return state.frontendViewsOpened.reduce<ReduceAccumulator>(
			(acc, view, index) => {
				if (
					acc.earliestCreationTime == null ||
					view.creationTime.isBefore(acc.earliestCreationTime)
				) {
					return {
						earliestCreationTime: view.creationTime,
						index: index,
					};
				}
				return acc;
			},
			{ earliestCreationTime: null, index: 0 },
		).index;
	}
}

const excludeItemWithUuid = <T extends UUIDField>(uuid: string, items: T[]): T[] => {
	return items.filter((item) => {
		return item.uuid !== uuid;
	});
};

const excludeModalsWithViewId = (viewId: string, modals: LegacyModalViewState[]) => {
	return modals.filter((modal) => {
		return modal.view.view.id !== viewId;
	});
};

const modalToUppermost = (uuid: string, modals: LegacyModalViewState[]): LegacyModalViewState[] => {
	const newUppermost = modals.find((modal) => {
		return modal.uuid === uuid;
	});
	if (newUppermost) {
		return [newUppermost, ...excludeItemWithUuid(uuid, modals)];
	} else {
		console.error("Tried to set non-existing aavo modal view to uppermost view.");
		return modals;
	}
};

const handleSetActionViewEvents = (
	events: Array<ViewActionEvent>,
	startUpData: StartUpData,
	prevState: AavoViewsContextState,
): AavoViewsContextState => {
	const stateWithEvents: AavoViewsContextState = {
		...prevState,
		viewActionEvents: {
			version: prevState.viewActionEvents.version + 1,
			events: events,
		},
	};
	return events.reduce((prev, e) => {
		return updateModalsAfterEvent(e, prev, startUpData);
	}, stateWithEvents);
};

const updateModalsAfterEvent = (
	e: ViewActionEvent,
	state: AavoViewsContextState,
	startUpData: StartUpData,
) => {
	if (isOpenFloatingViewEvent(e)) {
		return openLegacyModal(e, e.action.target.viewId, startUpData, state);
	} else if (isOpenDynamicViewEvent(e)) {
		return aavoViewsReducer(state, {
			type: "openLegacyModal",
			modalViewState: {
				sourceViewId: e.senderViewId,
				viewParams: getModalViewParamsFromEvent(e),
				view: getDynamicViewDefinitionFromEvent(e),
			},
		});
	} else if (isCloseUppermostFloatingViewEvent(e)) {
		return aavoViewsReducer(state, {
			type: "closeUppermostLegacyModal",
		});
	} else if (isCloseFloatingViewEvent(e)) {
		return aavoViewsReducer(state, {
			type: "closeLegacyModalsWithId",
			viewId: getActionTargetViewId(e),
		});
	} else if (isOpenFrontendModalViewEvent(e)) {
		const frontendViewKey = e.action.target.viewId;

		if (isModalFrontendView(frontendViewKey)) {
			return aavoViewsReducer(state, {
				type: "openFrontendModal",
				viewActionEvent: e,
			});
		} else {
			const fallbackView = e.action.params.find(
				(param: any) => param.key === "__FALLBACK_VIEW_ID__",
			)?.value;
			if (fallbackView == null) {
				return { ...state };
			}
			return openLegacyModal(e, fallbackView, startUpData, state);
		}
	} else if (isRefreshSourceViewEvent(e)) {
		const modalState = state.legacyModals.find((modal) => modal.view.view.id === e.senderViewId);
		if (modalState) {
			modalState.refreshSourceView?.();
		}
		return { ...state };
	} else {
		return { ...state };
	}
};

const getActionTargetViewId = (event: ViewActionEvent) => {
	if (event.action.target.type === "view") return event.action.target.viewId;
	if (event.action.target.type === "self") return event.senderViewId;
	if (event.action.target.type === "sourceView") return event.senderSourceViewId;

	return undefined;
};

const openLegacyModal = (
	e: ViewActionEvent,
	viewId: string,
	startUpData: StartUpData,
	state: AavoViewsContextState,
): AavoViewsContextState => {
	const viewDefinition = getViewOrErrorFromStartUpData(viewId, startUpData);
	if (isErrorViewDefinition(viewDefinition)) {
		console.warn(
			`Action event ${OPEN_FLOATING_VIEW_ACTION_SPECIFIER} raised with invalid view id ${viewId}. The view does not exist in startUpData.`,
		);
	}

	return aavoViewsReducer(state, {
		type: "openLegacyModal",
		modalViewState: {
			sourceViewId: e.senderViewId,
			viewParams: getModalViewParamsFromEvent(e),
			view: viewDefinition,
		},
	});
};

const handleOpenFrontendModal = (
	actionEvent: ViewActionEvent,
	prevState: AavoViewsContextState,
): AavoViewsContextState => {
	const frontendViewKey = actionEvent.action.target.viewId;
	const frontendViewConfig = FRONTEND_VIEWS_BY_KEYS[frontendViewKey];
	if (frontendViewConfig === undefined) {
		console.error(`Trying to open unimplemented frontend view ${frontendViewKey}`);
		return { ...prevState };
	} else if (frontendViewConfig.type !== "modal") {
		console.error(`Trying to open non-modal frontend view ${frontendViewKey}`);
		return { ...prevState };
	} else {
		return {
			...prevState,
			frontendViewModals: [
				{
					...frontendViewConfig,
					viewParams: actionEvent.allParams,
					uuid: uuidv4(),
					sourceViewId: actionEvent.senderViewId,
				},
				...prevState.frontendViewModals,
			],
		};
	}
};

const isModalFrontendView = (viewId: string): boolean => {
	const frontendViewConfig = FRONTEND_VIEWS_BY_KEYS[viewId];
	return frontendViewConfig?.type === "modal";
};
