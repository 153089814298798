import { ControlChartSelectionApi } from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import { booleanColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { ControlChartGroupEditApi } from "src/api/generated/spc/controlChart/api/controlChartGroupEditApi.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";

export interface ControlChartGroupDataGridProps {
	controlChartParentGroupId: number;
	setSelectedControlChartGroup: (controlChartGroup: ChartGroupView | undefined) => void;
}

export const ControlChartGroupDataGrid = ({
	controlChartParentGroupId,
	setSelectedControlChartGroup,
}: ControlChartGroupDataGridProps) => {
	const { onlySelectedRow, refreshData, dataGridProps } = useClientSideDataGridModel({
		gridId: "ED13D4D585BACFEA",
		fetchData: (params) =>
			ControlChartSelectionApi.getControlChartGroups({
				parentGroupId: controlChartParentGroupId,
				...params,
			}),
		initialParams: {
			searchQuery: "",
		},
		getRowId: (row) => row.chartGroupId,
		onSelectionChanged: (selectedRows) => {
			setSelectedControlChartGroup(selectedRows[0]);
		},
	});

	return (
		<ControlledAsyncCrudDataGrid<ChartGroupView>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					editable: true,
					validate: "required",
					width: 250,
				}),
				booleanColumn({
					field: "isDefectGroup",
					headerName: i18n.t("is_defect_group"),
					editable: true,
				}),
				integerColumn({
					field: "taktTime",
					headerName: i18n.t("takt_time"),
				}),
				integerColumn({
					field: "chartGroupId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("CHART_GROUP", onlySelectedRow?.chartGroupId)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (value) => {
							await refreshData({ searchQuery: value });
						}}
					/>
				</>
			}
			add={() => {
				return {
					name: "",
					isDefectGroup: false,
					parentGroupId: controlChartParentGroupId,
					superGroupId: controlChartParentGroupId,
				};
			}}
			save={async ({ items }) =>
				await ControlChartGroupEditApi.save({
					chartGroups: items,
				})
			}
			remove={async ({ items }) =>
				await ControlChartGroupEditApi.delete_({
					chartGroupIds: items.map((item) => item.chartGroupId),
				})
			}
			{...dataGridProps}
		/>
	);
};
