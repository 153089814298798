import { PartConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import i18n from "i18next";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorFormResult } from "../../configuratorForm/PartConfiguratorForm";
import { SitePartTestConfiguratorResultsView } from "./SitePartTestConfiguratorResultsView";

export interface SitePartTestConfiguratorViewProps {
	sitePartRevisionId: number;
	closeDialog?: () => void;
}

export const SitePartTestConfiguratorView = ({
	sitePartRevisionId,
	closeDialog,
}: SitePartTestConfiguratorViewProps) => {
	const { openDialog } = useGenericDialog();

	const configuratorType: PartConfiguratorType = {
		type: "sitePartTest",
		sitePartRevisionId: sitePartRevisionId,
		isTestConfigurator: true
	};

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					onSubmit(result.value);
				} else {
					closeDialog?.();
				}
			}}
		/>
	);

	function onSubmit({ propertyValues, configurationSessionId }: PartConfiguratorFormResult) {
		openDialog(() => ({
			title: i18n.t("test_configuration"),
			content: (
				<SitePartTestConfiguratorResultsView
					configurationSessionId={configurationSessionId}
					configuratorType={configuratorType}
					propertyValues={propertyValues}
				/>
			),
		}));
	}
};
