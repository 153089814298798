import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
	booleanColumn,
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCustomerOrderLineStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderLineState.ts";
import { getSalesPartTypeLabels } from "src/api/generated/erp/db/types/enums/salesPartType.ts";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { SelectSiteField } from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { getShopOrderStateLabels } from "src/api/generated/erp/db/types/enums/shopOrderState.ts";
import { associate } from "src/utils/arrayUtils.ts";
import { getVatHandlingLabels } from "src/api/generated/erp/db/types/enums/vatHandling.ts";
import { AllCustomerOrderLinesDataGridApi } from "src/api/generated/erp/sales/customerOrder/api/allCustomerOrderLinesDataGridApi.ts";
import { getCustomerOrderStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { CustomerOrderLineExportView } from "src/api/generated/erp/db/types/tables/customerOrderLineExportView.ts";

export const AllCustomerOrderLinesDataGrid = () => {
	const { defaultSiteId } = useGlobalInitData();
	const { tenantConfig } = useTenantCustomizations();
	const tenantStateLabelsByKeys = associate(
		tenantConfig.erp.customerOrderTenantStates,
		(state) => state.key,
		(state) => state.label,
	);
	return (
		<ServerSideDataGridModel
			gridId={"AE51F8EFC22A3217ECEE"}
			fetchData={AllCustomerOrderLinesDataGridApi.getAllCustomerOrderLines}
			initialParams={{
				siteId: genericNullableValue<number>(defaultSiteId),
			}}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.customerOrderLineId}
			render={({ dataGridProps, currentParams, refreshData }) => {
				return (
					<CrudDataGrid<CustomerOrderLineExportView>
						columns={[
							integerColumn({
								field: "customerOrderId",
								headerName: i18n.t("customer_order_id"),
								width: 75,
							}),
							textColumn({
								field: "siteName",
								headerName: i18n.t("site_short"),
								width: 80,
							}),
							textColumn({
								field: "customerName",
								headerName: i18n.t("customer"),
								width: 150,
							}),
							textColumn({
								field: "orderReference",
								headerName: i18n.t("reference"),
								width: 150,
							}),
							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("line_number_shortened"),
								width: 60,
							}),
							textColumn({
								field: "configurationOrPartDescription",
								headerName: i18n.t("part"),
								width: 250,
							}),
							floatColumn({
								field: "salesQuantityAsSalesUnits",
								headerName: i18n.t("ordered_quantity"),
							}),
							textColumn({
								field: "salesUnit",
								headerName: i18n.t("sales_unit"),
							}),
							textColumn({
								field: "mark",
								headerName: i18n.t("mark"),
								width: 200,
							}),
							textColumn({
								field: "customerPoNo",
								headerName: i18n.t("customer_po_no"),
							}),
							dateColumn({
								field: "plannedDeliveryDate",
								headerName: i18n.t("planned_delivery_date_short"),
							}),
							enumColumn({
								field: "customerOrderState",
								headerName: i18n.t("customer_order_state"),
								enumLabels: getCustomerOrderStateLabels(),
							}),
							tenantConfig.erp.useCustomerOrderTenantStates &&
								textColumn({
									headerName: tenantConfig.erp.customerOrderTenantStateColumnLabel,
									field: "tenantState",
									valueGetter: (_, row) => tenantStateLabelsByKeys[row.tenantState ?? ""] ?? "-",
								}),
							textColumn({
								field: "deliveryCity",
								headerName: i18n.t("delivery_city"),
							}),
							textColumn({
								field: "deliveryAddress_1",
								headerName: i18n.t("delivery_address"),
							}),
							textColumn({
								field: "responsiblePersonName",
								headerName: i18n.t("responsible_person"),
							}),
							textColumn({
								field: "salespersonName",
								headerName: i18n.t("salesperson"),
							}),
							tenantConfig.erp.customerOrderCheckingEnabled &&
								dateColumn({
									field: "checkedAt",
									headerName: i18n.t("checked_at"),
								}),
							dateColumn({
								field: "confirmedAt",
								headerName: i18n.t("confirmed_at"),
							}),
							tenantConfig.erp.customerOrderFreezingEnabled &&
								dateColumn({
									field: "frozenAt",
									headerName: i18n.t("frozen_at"),
								}),
							textColumn({
								field: "contactName",
								headerName: i18n.t("contact_person"),
							}),
							textColumn({
								field: "externalOrderId",
								headerName: i18n.t("external_order_id"),
							}),
							textColumn({
								field: "deliveryTermsCode",
								headerName: i18n.t("delivery_terms"),
							}),
							textColumn({
								field: "deliveryMethodCode",
								headerName: i18n.t("delivery_method"),
							}),
							textColumn({
								field: "deliveryTermsDestination",
								headerName: i18n.t("delivery_terms_destination"),
							}),
							booleanColumn({
								field: "capacityReservation",
								headerName: i18n.t("capacity_reservation"),
							}),
							integerColumn({
								field: "transportDuration",
								headerName: i18n.t("transport_duration"),
							}),
							textColumn({
								field: "transportRouteName",
								headerName: i18n.t("transport_route"),
							}),
							textColumn({
								field: "paymentTermDesc",
								headerName: i18n.t("payment_term"),
							}),
							enumColumn({
								field: "vatHandling",
								headerName: i18n.t("vat_handling"),
								enumLabels: getVatHandlingLabels(),
							}),
							booleanColumn({
								field: "billingPlanEnabled",
								headerName: i18n.t("billing_plan"),
							}),
							textColumn({
								field: "customerOrderCreatedByUserName",
								headerName: i18n.t("customer_order_created_by"),
							}),
							dateTimeColumn({
								field: "customerOrderCreatedDate",
								headerName: i18n.t("customer_order_created_at"),
							}),
							textColumn({
								field: "customerOrderTypeName",
								headerName: i18n.t("customer_order_type"),
							}),
							enumColumn({
								field: "customerOrderState",
								headerName: i18n.t("customer_order_state"),
								enumLabels: getCustomerOrderStateLabels(),
							}),
							textColumn({
								field: "contactName",
								headerName: i18n.t("contact_person"),
							}),
							booleanColumn({
								field: "billingPlanEnabled",
								headerName: i18n.t("billing_plan"),
							}),
							textColumn({
								field: "customerOrderTypeName",
								headerName: i18n.t("customer_order_type"),
							}),

							floatColumn({
								field: "unitPrice",
								headerName: i18n.t("unit_price"),
								width: 90,
							}),
							floatColumn({
								field: "unitPriceWithVat",
								headerName: i18n.t("unit_price_with_vat"),
								width: 150,
							}),
							floatColumn({
								field: "discountPercentage",
								headerName: i18n.t("discount_percentage"),
								width: 80,
							}),
							floatColumn({
								field: "totalPrice",
								headerName: i18n.t("total_price"),
							}),
							floatColumn({
								field: "totalPriceWithVat",
								headerName: i18n.t("total_price_with_vat"),
								width: 155,
							}),
							textColumn({
								field: "salesPriceUnit",
								headerName: i18n.t("sales_price_unit"),
							}),
							enumColumn({
								field: "customerOrderLineState",
								headerName: i18n.t("state"),
								enumLabels: getCustomerOrderLineStateLabels(),
							}),
							enumColumn({
								field: "salesPartType",
								headerName: i18n.t("type"),
								enumLabels: getSalesPartTypeLabels(),
								width: 75,
							}),
							enumColumn({
								field: "acquisitionMethod",
								headerName: i18n.t("acquisition_method"),
								enumLabels: getAcquisitionMethodLabels(),
								width: 125,
							}),
							textColumn({
								field: "shopOrderBatchCode",
								headerName: i18n.t("shop_order_batch"),
							}),
							textColumn({
								field: "capacityQuantity",
								headerName: i18n.t("capacity_quantity"),
								width: 125,
							}),
							floatColumn({
								field: "unitCost",
								headerName: i18n.t("unit_cost"),
								width: 125,
							}),
							floatColumn({
								field: "totalCost",
								headerName: i18n.t("total_cost"),
								width: 135,
							}),
							textColumn({
								field: "vatCodeName",
								headerName: i18n.t("vat_code"),
							}),
							dateTimeColumn({
								field: "releaseDate",
								headerName: i18n.t("released"),
								width: 130,
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("warehouse_unit"),
								width: 105,
							}),
							textColumn({
								field: "purchaseUnit",
								headerName: i18n.t("purchase_unit"),
								width: 90,
							}),
							dateColumn({
								field: "lastPickingDate",
								headerName: i18n.t("last_picking_date"),
								width: 155,
							}),
							dateColumn({
								field: "lastShippingDate",
								headerName: i18n.t("last_shipping_date"),
								width: 150,
							}),
							floatColumn({
								field: "reservedQuantity",
								headerName: i18n.t("reserved"),
								width: 60,
							}),
							floatColumn({
								field: "pickedQuantity",
								headerName: i18n.t("picked"),
								width: 65,
							}),
							floatColumn({
								field: "deliveredQuantity",
								headerName: i18n.t("delivered"),
								width: 70,
							}),
							floatColumn({
								field: "invoicedQuantity",
								headerName: i18n.t("invoiced"),
								width: 75,
							}),
							integerColumn({
								field: "customerOrderLineId",
								headerName: i18n.t("id"),
								width: 75,
							}),
							integerColumn({
								field: "shopOrderId",
								headerName: i18n.t("shop_order_id"),
								width: 70,
							}),
							enumColumn({
								field: "shopOrderState",
								headerName: i18n.t("state"),
								enumLabels: getShopOrderStateLabels(),
							}),
							textColumn({ field: "shopOrderBatchName", headerName: i18n.t("production_batch") }),
							textColumn({ field: "productionLineName", headerName: i18n.t("production_line") }),
							textColumn({ field: "sourceRefData", headerName: i18n.t("reference"), width: 200 }),
							floatColumn({ field: "quantity", headerName: i18n.t("shop_order_quantity") }),
							textColumn({ field: "partUnit", headerName: i18n.t("unit") }),
							textColumn({ field: "packageInfo", headerName: i18n.t("packaging_info") }),
							textColumn({ field: "shopOrderNote", headerName: i18n.t("shop_order_note") }),
							integerColumn({ field: "partRevision", headerName: i18n.t("revision") }),
							floatColumn({ field: "capacityQuantity", headerName: i18n.t("capacity_quantity") }),
							dateColumn({ field: "demandDate", headerName: i18n.t("demand_date") }),
							dateTimeColumn({
								field: "plannedBeginDate",
								headerName: i18n.t("planned_begin_date"),
								width: 150,
							}),
							dateTimeColumn({
								field: "plannedEndDate",
								headerName: i18n.t("planned_end_date"),
								width: 150,
							}),
							dateTimeColumn({
								field: "workInstructionPrinted",
								headerName: i18n.t("work_instruction_printed"),
								width: 150,
							}),
							dateTimeColumn({
								field: "releasedDate",
								headerName: i18n.t("shop_order_released_at"),
								width: 150,
							}),
							dateTimeColumn({
								field: "actualBeginDate",
								headerName: i18n.t("shop_order_actual_begin_date"),
								width: 200,
							}),
							dateTimeColumn({
								field: "actualEndDate",
								headerName: i18n.t("shop_order_actual_end_date"),
								width: 200,
							}),
							floatColumn({
								field: "completedQuantity",
								headerName: i18n.t("completed_quantity"),
								width: 150,
							}),
							dateTimeColumn({ field: "cancelledDate", headerName: i18n.t("cancelled_at") }),
						]}
						actionBarComponents={
							<>
								<SelectSiteField
									value={currentParams.siteId}
									onChange={async (siteId) => {
										await refreshData({ siteId: siteId });
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
