import { SubConfiguratorFormApi } from "src/api/generated/erp/configurator/api/form/subConfiguratorFormApi.ts";
import { SubConfiguratorPartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/subConfiguratorPartConfiguratorType.ts";
import { TransformedConfigurationComponent_SubConfiguratorList } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import { ConfigurationPropertyValue_SubConfigurationList } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import React, { useEffect } from "react";
import * as uuid from "uuid";
import { SubConfiguratorListItemState } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorListItemState.tsx";
import { swapItems } from "src/utils/arrayUtils.ts";
import { SubConfiguratorListStatelessView } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorListStatelessView.tsx";

export interface SubConfiguratorListStatefulViewProps {
	component: TransformedConfigurationComponent_SubConfiguratorList;
	parentConfiguratorType: PartConfiguratorType;
	parentPropertyValues: ConfigurationPropertyValues;
	initialValues: ConfigurationPropertyValue_SubConfigurationList;
	onSubmit: (value: ConfigurationPropertyValue_SubConfigurationList) => void;
	apiRef: React.MutableRefObject<SubConfiguratorListStatefulViewApi | null>;
}

export interface OnSubConfiguratorInputChangedParams {
	input: ConfiguratorInput;
	errors: string[];
	configurationSessionId: string;
	refreshSubConfigurationValue?: boolean;
}

export interface SubConfiguratorListStatefulViewApi {
	getValue: () => Promise<ConfigurationPropertyValue_SubConfigurationList>;
}

export const SubConfiguratorListStatefulView = ({
	component,
	parentConfiguratorType,
	parentPropertyValues,
	initialValues,
	onSubmit: onSubmitProp,
	apiRef,
}: SubConfiguratorListStatefulViewProps) => {
	const [subConfiguratorStates, setSubConfiguratorStates] = React.useState<SubConfiguratorListItemState[]>(
		initialValues.value.map((initialValue) => ({
			key: uuid.v4(),
			subConfigurationValue: initialValue,
			configurationSessionId: undefined,
			subConfiguratorInput: undefined,
			isLoading: false,
			errors: initialValue.error ? [initialValue.error] : [],
		})),
	);

	const [selectedSubConfiguratorKey, setSelectedSubConfiguratorKey] = React.useState<string | undefined>(
		subConfiguratorStates[0]?.key,
	);

	const subConfiguratorType: SubConfiguratorPartConfiguratorType = {
		type: "subConfigurator",
		subConfiguratorCatalogPartId: component.catalogPartId,
		parentConfiguratorType: parentConfiguratorType,
		parentProperties: parentPropertyValues,
		isTestConfigurator: parentConfiguratorType.isTestConfigurator,
	};

	useEffect(() => {
		apiRef.current = {
			getValue: getUpToDateSubConfigurationListValue,
		};
	});

	return (
		<SubConfiguratorListStatelessView
			subConfiguratorType={subConfiguratorType}
			subConfiguratorStates={subConfiguratorStates}
			selectedSubConfiguratorKey={selectedSubConfiguratorKey}
			selectSubConfigurator={selectSubConfigurator}
			addSubConfigurator={addSubConfigurator}
			removeSubConfigurator={removeSubConfigurator}
			swapSubConfigurators={swapSubConfigurators}
			onSubConfiguratorInputChanged={onSubConfiguratorInputChanged}
			onSubmit={onSubmit}
		/>
	);

	async function selectSubConfigurator(key: string) {
		const previousSelectedSubConfiguratorKey = selectedSubConfiguratorKey;
		setSelectedSubConfiguratorKey(key);
		if (previousSelectedSubConfiguratorKey != null) {
			await refreshSubConfiguratorValues(previousSelectedSubConfiguratorKey);
		}
	}

	async function addSubConfigurator() {
		const key = uuid.v4();
		const newSubConfiguratorState: SubConfiguratorListItemState = {
			key,
			subConfigurationValue: {
				type: "sub_configuration",
				label: "-",
				value: {},
				error: null,
			},
			configurationSessionId: undefined,
			subConfiguratorInput: undefined,
			isLoading: false,
			errors: [],
		};
		setSubConfiguratorStates((current) => [...current, newSubConfiguratorState]);
		await selectSubConfigurator(key);
	}

	function removeSubConfigurator(key: string) {
		const index = getSubConfiguratorIndex(key);
		const newSubConfiguratorStates = subConfiguratorStates.toSpliced(index, 1);
		setSubConfiguratorStates(newSubConfiguratorStates);
		if (selectedSubConfiguratorKey === key) {
			setSelectedSubConfiguratorKey(newSubConfiguratorStates[0]?.key);
		}
	}

	function swapSubConfigurators(key1: string, key2: string) {
		const index1 = getSubConfiguratorIndex(key1);
		const index2 = getSubConfiguratorIndex(key2);
		const newSubConfiguratorStates = swapItems(subConfiguratorStates, index1, index2);
		setSubConfiguratorStates(newSubConfiguratorStates);
	}

	async function onSubConfiguratorInputChanged(
		key: string,
		{
			input,
			configurationSessionId,
			refreshSubConfigurationValue,
			errors,
		}: OnSubConfiguratorInputChangedParams,
	) {
		const subConfiguratorState = getSubConfiguratorState(key);
		const newSubConfiguratorState = {
			...subConfiguratorState,
			configurationSessionId: configurationSessionId,
			subConfiguratorInput: input,
			errors: errors,
		};
		setSubConfiguratorState(key, newSubConfiguratorState);
		if (refreshSubConfigurationValue) {
			await refreshSubConfiguratorValues(key, newSubConfiguratorState);
		}
	}

	async function refreshSubConfiguratorValues(
		key: string,
		subConfiguratorState: SubConfiguratorListItemState = getSubConfiguratorState(key),
	): Promise<SubConfiguratorListItemState | undefined> {
		if (
			subConfiguratorState?.subConfiguratorInput == null ||
			subConfiguratorState.configurationSessionId == null
		) {
			return;
		}

		setSubConfiguratorState(key, {
			...subConfiguratorState,
			isLoading: true,
		});

		try {
			const newValues = await SubConfiguratorFormApi.getSubConfigurationValueFromInput({
				configuratorType: subConfiguratorType,
				subConfiguratorInput: subConfiguratorState.subConfiguratorInput,
				configurationSessionId: subConfiguratorState.configurationSessionId,
			});
			const newState = {
				...subConfiguratorState,
				subConfigurationValue: {
					...newValues,
					error: subConfiguratorState.errors[0],
				},
				isLoading: false,
			};
			setSubConfiguratorState(key, newState);
			return newState;
		} catch (e) {
			setSubConfiguratorState(key, {
				...subConfiguratorState,
				isLoading: false,
			});
			throw e;
		}
	}

	function setSubConfiguratorState(key: string, newState: SubConfiguratorListItemState) {
		setSubConfiguratorStates((prev) => {
			const currentIndex = getSubConfiguratorIndex(key);
			return prev.with(currentIndex, newState);
		});
	}

	function getSubConfiguratorState(key: string): SubConfiguratorListItemState {
		const index = getSubConfiguratorIndex(key);
		return subConfiguratorStates[index]!;
	}

	function getSubConfiguratorIndex(key: string): number {
		const index = subConfiguratorStates.findIndex((state) => state.key === key);
		if (index === -1) {
			throw Error(`SubConfiguratorState for key ${key} not found`);
		}
		return index;
	}

	async function onSubmit() {
		const finalSubConfiguratorStates = await getUpToDateSubConfiguratorStates();
		onSubmitProp({
			type: "sub_configuration_list",
			label: "",
			value: finalSubConfiguratorStates.map((state) => state.subConfigurationValue),
		});
	}

	async function getUpToDateSubConfiguratorStates(): Promise<SubConfiguratorListItemState[]> {
		if (selectedSubConfiguratorKey != null) {
			const selectedSubConfiguratorFinalState =
				await refreshSubConfiguratorValues(selectedSubConfiguratorKey);

			if (selectedSubConfiguratorFinalState != null) {
				return subConfiguratorStates.map((state) =>
					state.key === selectedSubConfiguratorKey ? selectedSubConfiguratorFinalState : state,
				);
			}
		}
		return subConfiguratorStates;
	}

	async function getUpToDateSubConfigurationListValue(): Promise<ConfigurationPropertyValue_SubConfigurationList> {
		const states = await getUpToDateSubConfiguratorStates();
		return {
			type: "sub_configuration_list",
			label: "",
			value: states.map((state) => state.subConfigurationValue),
		};
	}
};
