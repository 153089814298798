// This file is automatically generated. Do not edit manually.

import { CustomerOrder } from "src/api/generated/erp/db/types/tables/customerOrder";
import { ContractualTerm } from "src/api/generated/erp/db/types/tables/contractualTerm";
import { Customer } from "src/api/generated/erp/db/types/tables/customer";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress";
import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView";
import { CustomerOrderType } from "src/api/generated/erp/db/types/tables/customerOrderType";
import { DeliveryMethod } from "src/api/generated/erp/db/types/tables/deliveryMethod";
import { DeliveryTerm } from "src/api/generated/erp/db/types/tables/deliveryTerm";
import { PaymentTerm } from "src/api/generated/erp/db/types/tables/paymentTerm";
import { SalespersonView } from "src/api/generated/erp/db/types/tables/salespersonView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { TransportRoute } from "src/api/generated/erp/db/types/tables/transportRoute";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderFormApi {
	export async function getInitData(args: {
		customerOrderId: number | null | undefined;
	}): Promise<CustomerOrderFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderForm/getInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as CustomerOrderFormApi_InitData;
	}

	export async function insert(args: { customerOrder: CustomerOrder }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderForm/insert",
			method: "POST",
			data: {
				customerOrder: args.customerOrder,
			},
		});
		return response as number;
	}

	export async function update(args: {
		customerOrder: CustomerOrder;
		updatePlannedDeliveryDateToLines: boolean;
		updatePlannedDeliveryDateAcquisitionObjects: boolean;
		updatePlannedDeliveryDateToTasks: boolean;
		updatePlannedDeliveryDateToBillingPlan?: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderForm/update",
			method: "POST",
			data: {
				customerOrder: args.customerOrder,
				updatePlannedDeliveryDateToLines: args.updatePlannedDeliveryDateToLines,
				updatePlannedDeliveryDateAcquisitionObjects: args.updatePlannedDeliveryDateAcquisitionObjects,
				updatePlannedDeliveryDateToTasks: args.updatePlannedDeliveryDateToTasks,
				updatePlannedDeliveryDateToBillingPlan: args.updatePlannedDeliveryDateToBillingPlan,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderFormApi_InitData {
	canModifyBillingOptions: boolean;
	contractualTermOptions: Array<ContractualTerm>;
	customer: Customer | null | undefined;
	customerAddresses: Array<CustomerAddress> | null | undefined;
	customerOrder: CustomerOrderView | null | undefined;
	customerOrderTypeOptions: Array<CustomerOrderType>;
	defaultVatCodeId: number;
	deliveryMethodOptions: Array<DeliveryMethod>;
	deliveryTermOptions: Array<DeliveryTerm>;
	hasApprovedCommissionLines: boolean;
	hasOrderLines: boolean;
	paymentTermOptions: Array<PaymentTerm>;
	salespersonOptions: Array<SalespersonView>;
	siteOptions: Array<Site>;
	transportRouteOptions: Array<TransportRoute>;
	vatCodeOptions: Array<VatCode>;
}
