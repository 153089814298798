import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	CatalogPartRevisionEditApi,
	CatalogPartRevisionEditApi_FormInitData,
} from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionEditApi.ts";
import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision.ts";
import { FormCommonProps, FormSubmitResult } from "src/components/common/forms/types.ts";
import { maxBy } from "src/utils/arrayUtils.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { AavoFormDivider } from "src/components/common/forms/AavoFormDivider.tsx";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { CatalogPartApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartApi.ts";
import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { CatalogPartRevisionApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionApi.ts";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { getCatalogPartRevisionStateLabel } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { CatalogPartRevisionConfiguratorSelectionsAsyncView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsAsyncView.tsx";
import { getConfiguratorProductFamilyVersionLabel } from "src/components/views/erp/configurator/configuratorUtils.ts";
import Typography from "@mui/material/Typography";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ShowConfirmDialogFunc } from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";

export interface CatalogPartRevisionFormProps extends FormCommonProps<number> {
	catalogPartId: number;
	catalogPartRevisionId: number | null;
}

interface FormValues extends CatalogPartRevision {
	importContentConfig: ImportContentFormValues;
}

interface ImportContentFormValues {
	importContent: boolean;
	sourceCatalogPartId: number;
	sourceRevision: CatalogPartRevisionView;
	importBom: boolean;
	importConfiguratorSelections: boolean;
	importDocuments: boolean;
}

export const CatalogPartRevisionForm = ({
	catalogPartId,
	catalogPartRevisionId,
	onCompleted,
	onFormEdited,
}: CatalogPartRevisionFormProps) => {
	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();

	return (
		<AsyncForm<CatalogPartRevisionEditApi_FormInitData, FormValues, number>
			fetch={() =>
				CatalogPartRevisionEditApi.getFormInitData({
					catalogPartId: catalogPartId,
					catalogPartRevisionId: catalogPartRevisionId,
				})
			}
			getDefaultValues={getDefaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={(values) =>
				submit({
					values,
					catalogPartRevisionId,
					catalogPartId,
					showDialog: openDialog,
					showConfirmDialog,
				})
			}
			render={(params) => <FormContent {...params} />}
		/>
	);

	function getDefaultValues({
		revision,
		previousRevision,
		nextCatalogPartRevisionNumber,
		productFamilyVersionOptions,
	}: CatalogPartRevisionEditApi_FormInitData) {
		return revision?.catalogPartRevisionId != null ?
				revision
			:	{
					catalogPartId: catalogPartId,
					catalogPartRevisionId: catalogPartRevisionId ?? undefined,
					revisionNumber: nextCatalogPartRevisionNumber,
					configurationProductFamilyVersionId: maxBy(
						productFamilyVersionOptions,
						(version) => version.versionNumber,
					)?.configurationProductFamilyVersionId,
					importContentConfig: {
						importContent: true,
						sourceCatalogPartId: catalogPartId,
						sourceRevision: previousRevision ?? undefined,
						importBom: true,
						importDocuments: true,
						importConfiguratorSelections: true,
					},
				};
	}
};

interface FormContentProps
	extends AsyncFormContentParams<CatalogPartRevisionEditApi_FormInitData, FormValues> {}

const FormContent = (props: FormContentProps) => {
	const {
		data: { revision, productFamilyVersionOptions, partIsConfigurable },
		control,
		trigger,
	} = props;

	const isNewRevision = revision == null;

	return (
		<>
			<FormNumberField
				control={control}
				name={"revisionNumber"}
				label={i18n.t("revision_number")}
				rules={requireRule()}
				disabled={revision?.state === "DESIGNED"}
			/>
			{partIsConfigurable && (
				<FormSelectField
					control={control}
					name={"configurationProductFamilyVersionId"}
					label={i18n.t("product_family_version")}
					options={productFamilyVersionOptions}
					getOptionKey={(o) => o.configurationProductFamilyVersionId}
					getOptionLabel={getConfiguratorProductFamilyVersionLabel}
					disabled={revision != null}
					rules={requireRule()}
					onChange={async () => {
						await trigger("importContentConfig.sourceRevision");
					}}
				/>
			)}
			<FormTextField control={control} name={"note"} label={i18n.t("comment")} />
			{isNewRevision && <ImportContentSection {...props} />}
		</>
	);
};

const ImportContentSection = ({
	data: { partIsConfigurable, productFamilyVersionOptions },
	control,
	watch,
	setValue,
}: FormContentProps) => {
	const importSourceCatalogPartId = watch("importContentConfig.sourceCatalogPartId");

	const selectedProductFamilyVersionId = watch("configurationProductFamilyVersionId");
	const selectedProductFamilyVersion = productFamilyVersionOptions.find(
		(version) => version.configurationProductFamilyVersionId === selectedProductFamilyVersionId,
	);
	const importSourceRevision = watch("importContentConfig.sourceRevision");
	const productFamilyWillBeUpgraded =
		selectedProductFamilyVersion != null &&
		importSourceRevision?.configurationProductFamilyVersionNumber != null &&
		selectedProductFamilyVersion.versionNumber >
			importSourceRevision.configurationProductFamilyVersionNumber;

	return (
		<>
			<AavoFormDivider>{i18n.t("import_content")}</AavoFormDivider>
			<FormCheckbox
				control={control}
				name={"importContentConfig.importContent"}
				label={i18n.t("import_content_from_existing_revision")}
			/>
			{watch("importContentConfig.importContent") && (
				<>
					<FormAsyncSelectField
						control={control}
						name={"importContentConfig.sourceCatalogPartId"}
						label={i18n.t("from_part")}
						fetchOptions={({ searchQuery, currentSelection }) =>
							CatalogPartApi.getCatalogPartOptions({
								searchQuery,
								currentSelection,
							})
						}
						getOptionKey={(option: CatalogPart) => option.catalogPartId}
						getOptionLabel={(option) =>
							concatWithPipe(option.partNo, option.description_1, option.description_2)
						}
						onChange={() => {
							setValue("importContentConfig.sourceRevision", null);
						}}
					/>
					<FormLazySelectField
						control={control}
						name={"importContentConfig.sourceRevision"}
						label={i18n.t("from_revision")}
						disabled={importSourceCatalogPartId == null}
						fetchOptions={async () => {
							if (importSourceCatalogPartId == null) return [];
							return await CatalogPartRevisionApi.getCatalogPartRevisions({
								catalogPartId: importSourceCatalogPartId,
							});
						}}
						getOptionKey={(o: CatalogPartRevisionView) => o.catalogPartRevisionId}
						getOptionLabel={(o: CatalogPartRevisionView) =>
							concatWithPipe(
								o.revisionNumber,
								getCatalogPartRevisionStateLabel(o.state),
								o.note,
							)
						}
						selectionToFieldValue={(_, revision) => revision}
						fieldValueToKey={(value) => value.catalogPartRevisionId}
						rules={{
							validate: (_, values) =>
								validateImportSourceRevision(values, productFamilyVersionOptions),
						}}
					/>
					{productFamilyWillBeUpgraded && (
						<Typography
							color={"secondary.main"}
							children={i18n.t(
								"product_family_version_will_be_upgraded_automatically_to_version",
								{
									targetVersion: selectedProductFamilyVersion?.versionNumber,
								},
							)}
						/>
					)}
					<FormCheckbox
						control={control}
						name={"importContentConfig.importBom"}
						label={i18n.t("import_bom")}
					/>
					<FormCheckbox
						control={control}
						name={"importContentConfig.importDocuments"}
						label={i18n.t("catalog_part_revision_form.import_documents")}
					/>
					{partIsConfigurable && (
						<FormCheckbox
							control={control}
							name={"importContentConfig.importConfiguratorSelections"}
							label={i18n.t("import_configurator_selections")}
						/>
					)}
				</>
			)}
		</>
	);
};

interface SubmitParams {
	catalogPartId: number;
	catalogPartRevisionId: number | null;
	values: FormValues;
	showDialog: OpenGenericDialogFunc;
	showConfirmDialog: ShowConfirmDialogFunc;
}

async function submit(submitParams: SubmitParams): Promise<FormSubmitResult<number>> {
	const { values, catalogPartRevisionId } = submitParams;

	if (catalogPartRevisionId == null) {
		return await insert(submitParams);
	} else {
		await CatalogPartRevisionEditApi.update({
			catalogPartRevision: values,
		});
		return catalogPartRevisionId;
	}
}

async function insert(submitParams: SubmitParams): Promise<FormSubmitResult<number>> {
	const { values, catalogPartId, showDialog, showConfirmDialog } = submitParams;
	const { importContentConfig, ...catalogPartRevision } = values;

	const catalogPartRevisionId = await CatalogPartRevisionEditApi.insert({
		catalogPartId: catalogPartId,
		revisionNumber: catalogPartRevision.revisionNumber,
		note: catalogPartRevision.note,
		configurationProductFamilyVersionId: catalogPartRevision.configurationProductFamilyVersionId,
		importContent: {
			sourceRevisionId: importContentConfig.sourceRevision!.catalogPartRevisionId,
			importBom: importContentConfig.importBom,
			importConfiguratorSelections: importContentConfig.importConfiguratorSelections,
			importDocuments: importContentConfig.importDocuments,
		},
	});

	const productFamilyVersionUpgraded =
		importContentConfig.importConfiguratorSelections &&
		catalogPartRevision.configurationProductFamilyVersionId != null &&
		importContentConfig.sourceRevision.configurationProductFamilyVersionId !=
			catalogPartRevision.configurationProductFamilyVersionId;

	if (productFamilyVersionUpgraded) {
		const openComponentSelectionConfirmed = await showConfirmDialog({
			title: i18n.t("product_family_version_upgraded"),
			message: i18n.t("confirm_open_component_selection_after_product_family_version_upgrade_message"),
			cancelButtonText: i18n.t("no"),
		});
		if (openComponentSelectionConfirmed) {
			showDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
				title: i18n.t("configurator_components"),
				content: (
					<CatalogPartRevisionConfiguratorSelectionsAsyncView
						catalogPartRevisionId={catalogPartRevisionId}
						productFamilyVersionId={catalogPartRevision.configurationProductFamilyVersionId!}
						onDataDirtyStateChanged={onDataDirtyStateChanged}
						onSubmitted={async () => {
							await closeDialog();
						}}
						onCancelled={async () => {
							await closeDialog({ confirmIfEdited: true });
						}}
					/>
				),
			}));
		}
	}

	return catalogPartRevisionId;
}

function validateImportSourceRevision(
	values: FormValues,
	productFamilyVersionOptions: ConfigurationProductFamilyVersion[],
): string | undefined {
	if (values.importContentConfig.sourceCatalogPartId == null) return undefined;

	const sourceRevision = values.importContentConfig.sourceRevision;
	if (sourceRevision == null) return i18n.t("required");

	const selectedProductFamilyVersion = productFamilyVersionOptions.find(
		(version) =>
			version.configurationProductFamilyVersionId === values.configurationProductFamilyVersionId,
	);
	if (selectedProductFamilyVersion == null) return undefined;

	if (!values.importContentConfig.importConfiguratorSelections) return undefined;
	if (sourceRevision.configurationProductFamilyVersionNumber == null) return undefined;

	if (selectedProductFamilyVersion.versionNumber < sourceRevision.configurationProductFamilyVersionNumber)
		return i18n.t("cannot_import_revision_with_higher_product_family_version_than_target_version", {
			targetVersion: getConfiguratorProductFamilyVersionLabel(selectedProductFamilyVersion),
		});

	return undefined;
}
