import React, { useReducer } from "react";
import { Box, Divider, Drawer } from "@mui/material";
import { DrawerBottomFields } from "./DrawerBottomFields";
import { MainMenuTreeView } from "src/components/views/drawer/mainMenuTree/MainMenuTreeView";
import { getMainMenuItems } from "src/components/views/drawer/mainMenuTree/mainMenuItemMapping";
import { useLocation, useParams } from "react-router-dom";
import { VIEW_ID } from "src/urls";
import {
	flatMapMainMenuItem,
	getAncestorsOfMainMenuItem,
	getMainMenuItemTreeViewNodeKey,
	MainMenuItem,
} from "./mainMenuTree/mainMenuItem";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useFrontendViewsContext } from "src/components/views/frontendViews/useFrontendViewsContext.ts";
import { useStartUpData } from "src/contexts/useStartUpData.ts";
import { MainDrawerToolbar } from "./MainDrawerToolbar";
import { filterMenuItems } from "src/components/views/drawer/mainMenuTree/menuItemFiltering.ts";
import { StartUpData } from "src/api/mainApi.ts";
import {
	loadFavoriteViewKeys,
	saveFavoriteViewKeys,
} from "src/components/views/drawer/mainMenuFavoriteViewsStorage.ts";
import { FrontendViewsContextValue } from "src/components/views/frontendViews/FrontendViewsContext.tsx";

export interface MainDrawerProps {
	drawerOpen: boolean;
	closeDrawer: () => void;
	drawerWidth: number;
	wideScreen: boolean;
}

export const MainDrawer = ({ drawerOpen, closeDrawer, drawerWidth, wideScreen }: MainDrawerProps) => {
	return (
		<Box>
			<DrawerContentWrapper
				drawerOpen={drawerOpen}
				closeDrawer={closeDrawer}
				drawerWidth={drawerWidth}
				wideScreen={wideScreen}
			>
				<DrawerContent closeDrawer={closeDrawer} wideScreen={wideScreen} />
			</DrawerContentWrapper>
		</Box>
	);
};

interface DrawerContentWrapperProps extends React.PropsWithChildren {
	drawerOpen: boolean;
	closeDrawer: () => void;
	drawerWidth: number;
	wideScreen: boolean;
}

const DrawerContentWrapper = ({
	drawerOpen,
	drawerWidth,
	closeDrawer,
	wideScreen,
	children,
}: DrawerContentWrapperProps) => {
	// Render a responsive <Drawer> component using two variants, "permanent" for wide screens and
	// "temporary" for mobile and tablet sizes.
	if (wideScreen) {
		return (
			<Drawer
				variant="persistent"
				sx={{
					display: wideScreen ? "block" : "none",
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
						overflowY: "visible",
					},
				}}
				open={drawerOpen}
			>
				{children}
			</Drawer>
		);
	} else {
		return (
			<Drawer
				variant="temporary"
				open={drawerOpen}
				onClose={closeDrawer}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: wideScreen ? "none" : "block",
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
						overflowY: "visible",
						maxWidth: "100%",
					},
				}}
			>
				{children}
			</Drawer>
		);
	}
};

interface DrawerContentProps {
	closeDrawer: () => void;
	wideScreen: boolean;
}

const DrawerContent = ({ closeDrawer, wideScreen }: DrawerContentProps) => {
	const startUpData = useStartUpData();
	const routerLocation = useLocation();
	const { viewId: viewIdUrlParam } = useParams<typeof VIEW_ID>();
	const frontendViewsContextValue = useFrontendViewsContext();

	const [drawerState, dispatchDrawerState] = useReducer<
		typeof drawerStateReducer,
		DrawerStateInitializerArgs
	>(
		drawerStateReducer,
		{
			frontendViewsContext: frontendViewsContextValue,
			startUpData,
			routerLocation,
			viewIdUrlParam,
			favoriteViewKeys: loadFavoriteViewKeys(),
		},
		initializeDrawerState,
	);

	const { visibleTreeNodes, favoriteViewKeys, openNodes, initialSelectedView } = drawerState;

	return (
		<VerticalBox
			sx={{
				height: "100%",
			}}
		>
			<MainDrawerToolbar
				closeAllNodes={() => dispatchDrawerState({ type: "setOpenNodes", nodes: [] })}
				closeDrawer={closeDrawer}
				filterTreeNodes={(filterText) => {
					dispatchDrawerState({ type: "filterTreeNodes", filterText });
				}}
			/>
			<Divider />
			<MainMenuTreeView
				menuItems={visibleTreeNodes}
				openNodes={openNodes}
				setOpenNodes={(nodes: string[]) => dispatchDrawerState({ type: "setOpenNodes", nodes })}
				defaultSelectedItem={initialSelectedView}
				onViewMenuItemClicked={onViewMenuItemClicked}
				favoriteViewKeys={favoriteViewKeys}
				addViewToFavorites={(viewKey) =>
					dispatchDrawerState({ type: "setViewIsFavorite", viewKey, isFavorite: true })
				}
				removeViewFromFavorites={(viewKey) =>
					dispatchDrawerState({ type: "setViewIsFavorite", viewKey, isFavorite: false })
				}
			/>
			<Divider />
			<DrawerBottomFields />
		</VerticalBox>
	);

	function onViewMenuItemClicked() {
		if (!wideScreen) {
			closeDrawer();
		}
	}
};

interface DrawerState {
	startUpData: StartUpData;
	frontendViewsContext: FrontendViewsContextValue;
	visibleTreeNodes: MainMenuItem[];
	favoriteViewKeys: string[];
	openNodes: string[];
	filterText: string;
	initialSelectedView: MainMenuItem | null;
}

type DrawerAction =
	| { type: "setViewIsFavorite"; viewKey: string; isFavorite: boolean }
	| { type: "setOpenNodes"; nodes: string[] }
	| { type: "filterTreeNodes"; filterText: string };

function drawerStateReducer(state: DrawerState, action: DrawerAction): DrawerState {
	switch (action.type) {
		case "setOpenNodes":
			return {
				...state,
				openNodes: action.nodes,
			};
		case "setViewIsFavorite": {
			const favoriteViewKeys =
				action.isFavorite ?
					[...state.favoriteViewKeys, action.viewKey]
				:	state.favoriteViewKeys.filter((key) => key !== action.viewKey);

			// This is a side effect and against guidelines, but it doesn't really hurt anything.
			saveFavoriteViewKeys(favoriteViewKeys);

			return {
				...state,
				favoriteViewKeys: favoriteViewKeys,
				visibleTreeNodes: initializeMainMenuItems({
					startUpData: state.startUpData,
					frontendViewsContext: state.frontendViewsContext,
					filterText: state.filterText,
					favoriteViewKeys: favoriteViewKeys,
				}),
			};
		}
		case "filterTreeNodes": {
			const newVisibleTreeNodes = initializeMainMenuItems({
				startUpData: state.startUpData,
				frontendViewsContext: state.frontendViewsContext,
				filterText: action.filterText,
				favoriteViewKeys: state.favoriteViewKeys,
			});
			const nodesToExpand = newVisibleTreeNodes
				.flatMap(flatMapMainMenuItem)
				.filter((item) => item.expanded === true)
				.map((item) => getMainMenuItemTreeViewNodeKey(item));
			return {
				...state,
				filterText: action.filterText,
				visibleTreeNodes: newVisibleTreeNodes,
				openNodes: nodesToExpand,
			};
		}
		default:
			return state;
	}
}

interface DrawerStateInitializerArgs {
	startUpData: StartUpData;
	frontendViewsContext: FrontendViewsContextValue;
	routerLocation: ReturnType<typeof useLocation>;
	viewIdUrlParam: string | undefined;
	favoriteViewKeys: string[];
}

function initializeDrawerState({
	startUpData,
	frontendViewsContext,
	routerLocation,
	viewIdUrlParam,
	favoriteViewKeys,
}: DrawerStateInitializerArgs): DrawerState {
	const filterText = "";
	const visibleTreeItems = initializeMainMenuItems({
		startUpData,
		frontendViewsContext,
		favoriteViewKeys,
		filterText,
	});

	const visibleTreeItemsFlat = visibleTreeItems.flatMap(flatMapMainMenuItem);

	const selectedView =
		visibleTreeItemsFlat.find((item) => {
			if (item.type !== "view") {
				return false;
			}
			const frontendView = frontendViewsContext.getFrontendViewByKey(item.key);
			if (frontendView) {
				return frontendView.url === routerLocation.pathname;
			} else {
				return viewIdUrlParam === item.key;
			}
		}) ?? null;

	const openNodes = [
		// Expand favorites group
		...visibleTreeItemsFlat.filter((item) => item.isFavoritesGroupShortcutItem),
		// Expand all ancestors of the selected view by default
		...(selectedView ? getAncestorsOfMainMenuItem(selectedView, visibleTreeItemsFlat) : []),
	].map(getMainMenuItemTreeViewNodeKey);

	return {
		visibleTreeNodes: visibleTreeItems,
		openNodes: openNodes,
		favoriteViewKeys: favoriteViewKeys,
		initialSelectedView: selectedView,
		filterText,
		startUpData,
		frontendViewsContext,
	};
}

function initializeMainMenuItems({
	startUpData,
	frontendViewsContext: { allMainMenuFrontendViews },
	favoriteViewKeys,
	filterText,
}: {
	startUpData: StartUpData;
	frontendViewsContext: FrontendViewsContextValue;
	favoriteViewKeys: string[];
	filterText: string;
}) {
	const rootMenuItems = getMainMenuItems(
		Object.values(startUpData.viewGroups),
		Object.values(startUpData.views),
		startUpData.frontendViews,
		allMainMenuFrontendViews,
		favoriteViewKeys,
	);

	return filterMenuItems(rootMenuItems, filterText);
}
