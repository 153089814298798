// This file is automatically generated. Do not edit manually.

import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartParentGroupEditApi {
	export async function save(args: {
		parentGroups: Array<ControlChartParentGroup>;
	}): Promise<Array<ControlChartParentGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartParentGroup/save",
			method: "POST",
			data: {
				parentGroups: args.parentGroups,
			},
		});
		return response as Array<ControlChartParentGroup>;
	}

	export async function delete_(args: { parentGroups: Array<ControlChartParentGroup> }): Promise<Array<void>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartParentGroup/delete",
			method: "POST",
			data: {
				parentGroups: args.parentGroups,
			},
		});
		return response as Array<void>;
	}
}
