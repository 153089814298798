import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import { OmegakeittiotPurchaseRequestDataGridActionBarComponents } from "./OmegakeittiotPurchaseRequestDataGridActionBarComponents";
import { OmegakeittiotShopOrderBatchContextMenuComponents } from "src/tenantCustomizations/tenants/omegakeittiot/OmegakeittiotShopOrderBatchContextMenuComponents.tsx";

export const omegakeittiotCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			configuratorEnabled: true
		},
		tasks: {
			enabled: false,
		},
		documentsEnabled: false,
	},
	purchaseRequestsDataGridActionBarComponents: OmegakeittiotPurchaseRequestDataGridActionBarComponents,
	erp: {
		production: {
			shopOrderBatchContextMenuComponents: OmegakeittiotShopOrderBatchContextMenuComponents,
			addShopOrdersToBatchConfiguratorFilterComponents: [
				{ componentUuid: "c376b1bb-04d6-441e-af99-241eb52169cb" },
			],
		},
	},
} as const;
