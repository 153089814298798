import {
    ClientSideDataGridModel,
    ClientSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid";
import {enumColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
    ConfiguratorLibraryComponentsApi
} from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibraryComponentsApi.ts";
import {
    ComponentInitialValues,
    ConfigurationComponentForm,
} from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentForm/ConfigurationComponentForm.tsx";
import {
    ConfiguratorComponentEditApi
} from "src/api/generated/erp/configurator/management/api/configuratorComponentEditApi.ts";
import {ConfiguratorLibraryVersion} from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion.ts";
import {ConfigurationComponent} from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import {
    getConfigurationComponentFieldTypeLabels
} from "src/api/generated/erp/db/types/enums/configurationComponentFieldType.ts";
import {maxBy} from "src/utils/arrayUtils.ts";
import {ConfigurationComponentView} from "src/api/generated/erp/db/types/tables/configurationComponentView.ts";
import * as uuid from "uuid";
import {useMessageDialog} from "src/components/common/dialogs/messageDialog/useMessageDialog.ts";

export interface ConfiguratorLibraryVersionComponentsDataGridProps {
    configuratorLibraryVersion: ConfiguratorLibraryVersion;
}

export const ConfiguratorLibraryVersionComponentsDataGrid = (
    props: ConfiguratorLibraryVersionComponentsDataGridProps,
) => {
    const {configuratorLibraryVersion} = props;
    return (
        <ClientSideDataGridModel
            fetchData={() =>
                ConfiguratorLibraryComponentsApi.getLibraryVersionComponents({
                    configuratorLibraryVersionId: configuratorLibraryVersion.configuratorLibraryVersionId,
                })
            }
            initialParams={{}}
            getRows={(data) => data}
            getRowId={(row) => row.configurationComponentId}
            gridId={"B1304A733CF104B9"}
            render={(params) => <Content {...props} {...params} />}
        />
    );
};

interface ContentParams
    extends ConfiguratorLibraryVersionComponentsDataGridProps,
        ClientSideDataGridModelRenderProps<
            ConfigurationComponentView[],
            ConfigurationComponentView,
            object
        > {
}

const Content = ({dataGridProps, configuratorLibraryVersion, data: components}: ContentParams) => {
    const showMessageDialog = useMessageDialog();

    return (
        <CrudDataGrid<ConfigurationComponentView>
            disableMultipleRowSelection
            columns={[
                textColumn({
                    headerName: i18n.t("component"),
                    field: "label",
                    width: 200,
                }),
                enumColumn({
                    headerName: i18n.t("type"),
                    field: "fieldType",
                    width: 300,
                    enumLabels: getConfigurationComponentFieldTypeLabels(),
                }),
                textColumn({
                    headerName: i18n.t("internal_name"),
                    field: "name",
                    width: 200,
                }),
                integerColumn({
                    headerName: i18n.t("id"),
                    field: "configurationComponentId",
                    width: 80,
                }),
                textColumn({
                    headerName: i18n.t("uuid"),
                    field: "libraryComponentSelfUuid",
                    width: 300,
                }),
            ]}
            form={{
                addRowEnabled: configuratorLibraryVersion.state === "INITIAL",
                editEnabled: true,
                dialogSize: "xl",
                dialogTitle: i18n.t("component"),
                component: ({row, onCompleted, onFormEdited}) => (
                    <ConfigurationComponentForm
                        component={getFormComponentInitialValues(row)}
                        disabled={configuratorLibraryVersion.state === "RELEASED"}
                        setIsDirty={() => onFormEdited()}
                        saveComponent={async (component) => {
                            if (component.configurationComponentId == null) {
                                return await ConfiguratorComponentEditApi.insertComponent({
                                    component,
                                    createProperty: false,
                                });
                            } else {
                                return await ConfiguratorComponentEditApi.updateComponent({
                                    component,
                                });
                            }
                        }}
                        onCompleted={async (result) => {
                            if (result.type === "success")
                                onCompleted({
                                    type: "success",
                                    value: result.value.configurationComponentId,
                                });
                            else onCompleted(result);
                        }}
                    />
                ),
            }}
            remove={async ({items}) => {
                const rowToDelete = items[0]!;

                const isAlreadyReferenced =
                    await ConfiguratorLibraryComponentsApi.getLibraryComponentIsReferencedByAny({
                        libraryComponentUuid: rowToDelete.libraryComponentSelfUuid!,
                    });
                if (isAlreadyReferenced) {
                    await showMessageDialog({
                        title: i18n.t("error"),
                        content: i18n.t(
                            "configurator_library_component.cannot_delete_because_referenced_error",
                        ),
                    });
                    return "interrupt";
                }

                await ConfiguratorComponentEditApi.deleteComponent({
                    componentId: rowToDelete.configurationComponentId,
                });
            }}
            {...dataGridProps}
        />
    );

    function getFormComponentInitialValues(
        rowToEdit: ConfigurationComponent | undefined,
    ): ComponentInitialValues {
        if (rowToEdit != null) return rowToEdit;
        else {
            return {
                configuratorLibraryVersionId: configuratorLibraryVersion.configuratorLibraryVersionId,
                libraryComponentSelfUuid: uuid.v4(),
                componentType: "FIELD",
                orderNum: (maxBy(components, (version) => version.orderNum)?.orderNum ?? 0) + 1,
                configurationComponentId: undefined,
                name: "",
                productFamilyVersionId: undefined,
                tabColumnCount: 0,
                parentTabComponentId: undefined,
            };
        }
    }
};
