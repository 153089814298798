import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { Controller, FieldPath, UseControllerProps } from "react-hook-form";
import React, { useEffect, useRef } from "react";
import { FormResult } from "src/components/common/forms/types.ts";
import {
	InputDialogFieldProps,
	InputDialogSelectionOption,
	InputDialogType,
	InputDialogValueType,
} from "src/components/common/dialogs/input/types.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker.tsx";
import { Dayjs } from "dayjs";
import { AavoDateTimePicker } from "src/components/common/inputFields/AavoDateTimePicker.tsx";
import { floatRule, integerRule, requireRule } from "src/components/common/forms/validation.ts";
import { SelectField } from "../../inputFields/SelectField.tsx";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField.tsx";
import i18n from "i18next";
import { AavoDateRangePicker } from "src/components/common/inputFields/AavoDateRangePicker.tsx";
import { AavoFileInput } from "src/components/common/inputFields/fileInput/AavoFileInput.tsx";

export interface InputDialogContentProps<T extends InputDialogType> {
	type: InputDialogType;
	fieldLabel: string;
	defaultValue: InputDialogValueType<T>;
	onCompleted: (result: FormResult<InputDialogValueType<T>>) => void;
	required?: boolean;
	fieldProps?: InputDialogFieldProps<T>;
}

export type InputDialogRules<T extends InputDialogType> = UseControllerProps<
	InputDialogFormValues<T>
>["rules"];

interface InputDialogFormValues<T extends InputDialogType> {
	value: InputDialogValueType<T>;
}

export const InputDialogContent = <T extends InputDialogType>({
	type,
	onCompleted,
	defaultValue,
	required,
	fieldProps,
	fieldLabel,
}: InputDialogContentProps<T>) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const fileInputAutoOpened = useRef(false);

	const submitForm = async (values: InputDialogFormValues<T>) => {
		return values.value;
	};

	const rules: InputDialogRules<T> = {
		...getInputFieldRules(type),
		...(required && requireRule()),
	};

	useEffect(() => {
		if (fileInputRef.current && !fileInputAutoOpened.current) {
			fileInputAutoOpened.current = true;
			fileInputRef.current.click();
		}
	}, []);

	return (
		<AavoForm<InputDialogFormValues<T>, InputDialogValueType<T>>
			defaultValues={{
				value: defaultValue,
			}}
			submit={submitForm}
			submitLabel={i18n.t("ok")}
			onCompleted={onCompleted}
			render={({ control }) => (
				<Controller
					control={control}
					name={"value" as FieldPath<InputDialogFormValues<T>>}
					rules={rules}
					render={({ field, fieldState }) =>
						renderInputField({
							type: type,
							label: fieldLabel,
							value: field.value,
							onChange: field.onChange,
							error: fieldState.error?.message,
							fieldProps: fieldProps,
							fileInputRef: fileInputRef,
						})
					}
				/>
			)}
		/>
	);
};

interface RenderInputFieldParams<T extends InputDialogType> {
	type: InputDialogType;
	value: InputDialogValueType<T>;
	label: string;
	onChange: (value: any) => void;
	error: string | undefined;
	fieldProps: InputDialogFieldProps<T> | undefined;
	fileInputRef: React.RefObject<HTMLInputElement>;
}

const renderInputField = <T extends InputDialogType>(
	params: RenderInputFieldParams<T>,
): React.ReactElement => {
	const { type, value, onChange, error, fieldProps, label, fileInputRef } = params;
	switch (type) {
		case "string":
			return (
				<AavoTextField
					value={value?.toString() ?? ""}
					onChange={onChange}
					error={error}
					label={label}
					multiline={fieldProps?.text?.multiLine}
					autoFocus
				/>
			);
		case "integer":
		case "decimal":
			return (
				<AavoTextField
					value={value?.toString() ?? ""}
					onChange={onChange}
					error={error}
					label={label}
				/>
			);
		case "boolean":
			return (
				<AavoCheckbox
					checked={value as boolean}
					label={label}
					onChange={(checked) => {
						onChange(checked);
					}}
				/>
			);
		case "date":
			return (
				<AavoDatePicker
					errorMessage={error}
					value={value as Dayjs | null}
					onChange={onChange}
					disablePast={fieldProps?.date?.disablePast}
				/>
			);
		case "datetime":
			return (
				<AavoDateTimePicker
					errorMessage={error}
					value={value as Dayjs | null}
					onChange={onChange}
					disablePast={fieldProps?.date?.disablePast}
				/>
			);
		case "dateRange": {
			const typedValue = value as { start: Dayjs; end: Dayjs } | null;
			return (
				<AavoDateRangePicker
					value={typedValue === null ? [null, null] : [typedValue.start, typedValue.end]}
					onChange={([start, end]) => {
						onChange({ start: start, end: end });
					}}
					disablePast={fieldProps?.date?.disablePast}
					errorMessage={error}
					autoFocus
				/>
			);
		}
		case "singleFile":
			return (
				<AavoFileInput
					multiple={false}
					value={value as File | null}
					onChange={onChange}
					inputRef={fileInputRef}
				/>
			);
		case "multipleFiles":
			return (
				<AavoFileInput
					multiple={true}
					value={value as File[]}
					onChange={onChange}
					inputRef={fileInputRef}
				/>
			);
		case "singleNumberSelect":
			return renderSelectField(params);
	}
};
const renderSelectField = <T extends InputDialogType>({
	value,
	onChange,
	fieldProps,
	label,
}: RenderInputFieldParams<T>) => {
	const optionsProvider = fieldProps?.selection?.options ?? [];

	const commonProps = {
		getOptionKey: (option: InputDialogSelectionOption<T>) => option.value as number,
		getOptionLabel: (option: InputDialogSelectionOption<T>) => option.label,
		label: label,
	};

	if (typeof optionsProvider === "function") {
		return (
			<AsyncSelectField
				{...commonProps}
				fetchOptions={({ searchQuery, currentSelection }) =>
					optionsProvider({
						searchQuery,
						currentSelection: currentSelection as InputDialogValueType<T>,
					})
				}
				onChange={(option) => {
					onChange(option?.value);
				}}
				defaultValue={value as number | null}
			/>
		);
	} else {
		return (
			<SelectField
				options={optionsProvider}
				onChange={onChange}
				value={value as number | null}
				{...commonProps}
			/>
		);
	}
};

const getInputFieldRules = <T extends InputDialogType>(
	type: InputDialogType,
): InputDialogRules<T> | undefined => {
	switch (type) {
		case "integer":
			return integerRule() as InputDialogRules<T>;
		case "decimal":
			return floatRule() as InputDialogRules<T>;
		default:
			return undefined;
	}
};
