import elmWebComponents from "./elmWebComponent";
import { Elm as ElmInputFieldEn } from "../../../elm-js-source/en/InputFieldComponent";
import { Elm as ElmInputFieldFi } from "../../../elm-js-source/fi/InputFieldComponent";
import equal from "fast-deep-equal";
import { v4 as uuidv4 } from "uuid";

export function registerInputFieldWebComponent(userLang) {
	const ElmInputField = userLang === "en" ? ElmInputFieldEn : ElmInputFieldFi;
	elmWebComponents.register(
		"input-field-component",
		ElmInputField.InputField.InputFieldComponent,
		{
			defaultFlags: {},
			staticFlags: {
				idPrefix: uuidv4()
			},
			portProperties: [
				{
					propName: "fieldDefinition",
					portName: "fieldDefinitionChanged",
					type: Object,
					hasChanged: (newVal, oldVal) => {
						if (!newVal || !oldVal) return true;
						// Only identifier, label or selection field options can be updated on the fly.
						// If identifier changes, whole field is updated.
						return (
							newVal.identifier !== oldVal.identifier ||
							newVal.label !== oldVal.label ||
							newVal.required !== oldVal.required ||
							!equal(newVal.type.options, oldVal.type.options)
						);
					}
				},
				{
					propName: "fieldValue",
					portName: "setValue",
					type: Object,
					hasChanged: (newVal, oldVal) => !equal(newVal, oldVal)
				},
				{
					propName: "readOnly",
					portName: "isReadOnlyChanged",
					type: Boolean
				},
				{
					propName: "params",
					portName: "paramsChanged",
					type: Array
				}
			],
			portsToEvents: [
				{ portName: "stateChanged", eventName: "stateChanged" },
				{ portName: "fieldSubmitted", eventName: "fieldSubmitted" },
				{ portName: "fieldLostFocus", eventName: "fieldLostFocus" }
			],
			portSubscriptions: [
				{
					portName: "focusElementWithCssSelector",
					handler: ({ rootElement, data: selector }) => {
						// Have to use hack with setTimeout, because field may not be rendered yet
						// when event is raised.
						focusElementWithCssSelector(rootElement, selector);
						setTimeout(
							() =>
								focusElementWithCssSelector(
									rootElement,
									selector
								),
							10
						);
					}
				}
			],
			useShadowRoot: false
		}
	);
}

function focusElementWithCssSelector(rootElement, selector) {
	const element = rootElement.querySelector(selector);
	if (!element) return;
	element.focus();
}
