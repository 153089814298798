// This file is automatically generated. Do not edit manually.

import { PartRevisionOperationView } from "src/api/generated/erp/db/types/tables/partRevisionOperationView";
import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { PartRevisionView } from "src/api/generated/erp/db/types/tables/partRevisionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartRevisionOperationsDataGridApi {
	export async function getData(args: {
		partRevisionId: number;
	}): Promise<SitePartRevisionOperationsDataGridApi_Data> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/revision/operationsDataGrid/getData",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
			},
		});
		return response as SitePartRevisionOperationsDataGridApi_Data;
	}

	export async function save(args: {
		partRevisionId: number;
		partRevisionOperations: Array<PartRevisionOperationView>;
	}): Promise<Array<PartRevisionOperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/revision/operationsDataGrid/save",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
				partRevisionOperations: args.partRevisionOperations,
			},
		});
		return response as Array<PartRevisionOperationView>;
	}

	export async function delete_(args: {
		partRevisionId: number;
		partRevisionOperations: Array<PartRevisionOperationView>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/revision/operationsDataGrid/delete",
			method: "POST",
			data: {
				partRevisionId: args.partRevisionId,
				partRevisionOperations: args.partRevisionOperations,
			},
		});
		return response as void;
	}
}

export interface SitePartRevisionOperationsDataGridApi_Data {
	operationOptions: Array<Operation>;
	partRevision: PartRevisionView;
	partRevisionOperations: Array<PartRevisionOperationView>;
}
