import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { useFieldArray, UseFieldArrayReturn } from "react-hook-form";
import { faArrowDown, faArrowUp, faExternalLink, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import {
	ConfigurationFieldSelectionOptions_Option,
	ConfigurationFieldSelectionOptions_OptionFile,
} from "src/api/generated/io/aavo/applications/db/erp/types/configurationFieldSelectionOptions.ts";
import { FormRichTextEditor } from "src/components/common/forms/fields/FormRichTextEditor.tsx";
import { FormSingleFileInputField } from "src/components/common/forms/fields/FormSingleFileInputField.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { UnmanagedFilesApi } from "src/api/generated/documents/unmanagedFiles/unmanagedFilesApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";

export interface SelectionFieldComponentOptionsFormProps
	extends FormCommonProps<ConfigurationFieldSelectionOptions_Option[]> {
	options: ConfigurationFieldSelectionOptions_Option[];
}

interface FormValues {
	options: OptionFormValues[];
}

interface OptionFormValues extends Omit<ConfigurationFieldSelectionOptions_Option, "file"> {
	file: OptionFileFormValues | undefined;
}

interface OptionFileFormValues extends ConfigurationFieldSelectionOptions_OptionFile {
	rawFile: File | undefined;
}

export const SelectionFieldComponentOptionsForm = (props: SelectionFieldComponentOptionsFormProps) => {
	const { onCompleted, onFormEdited, options: optionsInitial } = props;
	return (
		<AavoForm<FormValues, ConfigurationFieldSelectionOptions_Option[]>
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submitLabel={i18n.t("ok")}
			render={(contentParams) => <FormContent {...contentParams} />}
		/>
	);

	function getDefaultValues() {
		return {
			options: optionsInitial.map((option) => {
				return {
					...option,
					file:
						option.file == undefined ?
							undefined
						:	{
								...option.file,
								rawFile: new File([], option.file.fileName),
							},
				};
			}),
		};
	}

	async function submit(values: FormValues): Promise<ConfigurationFieldSelectionOptions_Option[]> {
		return await Promise.all(values.options.map(mapOptionOnSubmit));
	}

	async function mapOptionOnSubmit(
		option: OptionFormValues,
	): Promise<ConfigurationFieldSelectionOptions_Option> {
		if (option.file?.rawFile == null)
			return {
				...option,
				file: null,
			};

		const fileHandle = await uploadFile(option.file.rawFile);
		const fileUuid = await UnmanagedFilesApi.createUnmanagedFile({ fileHandle });
		return {
			...option,
			file: {
				fileUuid: fileUuid,
				fileName: option.file.rawFile.name,
			},
		};
	}
};

interface FormContentProps extends AavoFormContentParams<FormValues> {}

const FormContent = (props: FormContentProps) => {
	const { control } = props;

	const fieldArray = useFieldArray({
		control,
		name: "options",
	});

	return (
		<VerticalBox
			sx={{
				gap: 1,
				padding: 1,
			}}
		>
			{fieldArray.fields.map((field, index) => (
				<SingleOptionForm
					{...props}
					key={field.id}
					index={index}
					field={field}
					fieldArray={fieldArray}
				/>
			))}
			<AavoButton
				icon={faPlus}
				label={i18n.t("new")}
				variant={"outlined"}
				onClick={() =>
					fieldArray.append({
						key: "",
						label: "",
						infoText: "",
						file: undefined,
					})
				}
				sx={{
					alignSelf: "flex-start",
				}}
			/>
		</VerticalBox>
	);
};

interface SingleOptionFormProps extends FormContentProps {
	field: OptionFormValues & { id: string };
	fieldArray: UseFieldArrayReturn<FormValues, "options">;
	index: number;
}

const SingleOptionForm = ({
	index,
	field,
	fieldArray,
	control,
	formState: { dirtyFields },
}: SingleOptionFormProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<HorizontalBox
			key={field.id}
			sx={{
				border: "1px solid",
				borderColor: "grey.600",
				borderRadius: 1,
				paddingTop: 2,
				paddingBottom: 1,
				paddingLeft: 0,
				paddingRight: 1,
			}}
		>
			<VerticalBox gap={0.5}>
				<AavoButton
					icon={faArrowUp}
					disabled={index === 0}
					onClick={() => fieldArray.move(index, index - 1)}
				/>
				<AavoButton
					icon={faArrowDown}
					disabled={index === fieldArray.fields.length - 1}
					onClick={() => fieldArray.move(index, index + 1)}
				/>
				<AavoButton icon={faTimes} onClick={() => fieldArray.remove(index)} />
			</VerticalBox>
			<HorizontalBox gap={1} flex={1}>
				<VerticalBox
					sx={{
						flex: 1,
						gap: 2,
					}}
				>
					<FormTextField
						control={control}
						name={`options.${index}.key`}
						label={i18n.t("key")}
						rules={{
							required: i18n.t("required"),
							validate: (_, values) => validateKeysAreUnique(values),
						}}
					/>
					<FormTextField
						control={control}
						name={`options.${index}.label`}
						label={i18n.t("label")}
					/>
					<HorizontalBox gap={1}>
						<FormSingleFileInputField
							control={control}
							name={`options.${index}.file.rawFile`}
							label={i18n.t("image")}
							accept={"image/*"}
							placeholder={i18n.t("select_an_image")}
							sx={{
								flex: 1,
							}}
						/>
						{field.file != null && !dirtyFields?.options?.[index]?.file && (
							<AsyncButton
								icon={faExternalLink}
								label={i18n.t("show")}
								onClick={openOptionImage}
							/>
						)}
					</HorizontalBox>
				</VerticalBox>
				<FormRichTextEditor
					control={control}
					name={`options.${index}.infoText`}
					label={i18n.t("info_text")}
					sx={{
						flex: 2,
					}}
				/>
			</HorizontalBox>
		</HorizontalBox>
	);

	function validateKeysAreUnique(values: FormValues) {
		const keys = values.options.map((o) => o.key);
		const uniqueKeys = new Set(keys);
		if (keys.length !== uniqueKeys.size) {
			return i18n.t("keys_must_be_unique");
		}
	}

	async function openOptionImage() {
		if (field.file == null) return;

		const fileUrl = await UnmanagedFilesApi.getUnmanagedFilePresignedDownloadUrl({
			uuid: field.file.fileUuid,
		});
		openDialog({
			title: i18n.t("image"),
			size: "sm",
			content: (
				<VerticalBox p={1}>
					<img src={fileUrl} alt={i18n.t("image")} />
				</VerticalBox>
			),
		});
	}
};
