import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { showAsyncDialog } from "src/components/common/dialogs/asyncDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import i18n from "i18next";
import { ControlChartForm } from "src/components/views/spc/basedata/controlCharts/form/ControlChartForm.tsx";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { ControlChartSelectionApi } from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { SitePartActionApi } from "src/api/generated/erp/parts/sitePart/api/sitePartActionApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { PartView } from "src/api/generated/erp/db/types/tables/partView.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { useStoredState } from "src/utils/useStoredState.ts";
import { DeepPartial } from "react-hook-form";

export interface CreatePartDemandControlChartFormProps extends FormCommonProps<void> {
	part: PartView;
}

interface FormValues {
	parentGroupId: number;
	chartGroupId: number;
}

export const CreatePartDemandControlChartForm = (props: CreatePartDemandControlChartFormProps) => {
	const { part, onCompleted, onFormEdited } = props;
	const { openDialog } = useGenericDialog();
	const tenantCustomizations = useTenantCustomizations();
	const [storedDefaultValues, setStoredDefaultValues] = useStoredState<DeepPartial<FormValues>>({
		defaultValue: {
			parentGroupId: undefined,
			chartGroupId: undefined,
		},
		key: "7E76C44AF6F44D76",
	});
	return (
		<AavoForm
			defaultValues={storedDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	async function submit(values: FormValues) {
		const controlChartId = await showAsyncDialog<number>(openDialog, ({ onCompleted, onFormEdited }) => ({
			title: i18n.t("create_control_chart"),
			size: "lg",
			content: (
				<ControlChartForm
					controlChartId={null}
					onCompleted={onCompleted}
					onFormEdited={onFormEdited}
					chartGroupId={values.chartGroupId}
					emptyFormDefaultValues={tenantCustomizations.erp?.partManagement?.getPartDemandControlChartDefaultValues?.(
						part,
					)}
				/>
			),
		}));
		if (controlChartId === undefined) {
			return;
		}
		setStoredDefaultValues({
			parentGroupId: values.parentGroupId,
			chartGroupId: values.chartGroupId,
		});
		await SitePartActionApi.setPartDemandControlChartId({
			partId: part.partId,
			controlChartId: controlChartId,
		});
	}
};

interface FormContentProps extends CreatePartDemandControlChartFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control, watch }: FormContentProps) => {
	const parentGroupId = watch("parentGroupId");

	return (
		<>
			<>
				<FormAsyncSelectField
					control={control}
					name={"parentGroupId"}
					label={i18n.t("parent_group")}
					fetchOptions={ControlChartSelectionApi.getControlChartParentGroups}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => `${option.name} | ${option.description}`}
					rules={requireRule()}
				/>
				<FormAsyncSelectField
					control={control}
					name={"chartGroupId"}
					label={i18n.t("chart_group")}
					fetchOptions={(params) =>
						ControlChartSelectionApi.getControlChartGroups({
							parentGroupId: parentGroupId,
							...params,
						})
					}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => `${option.name} | ${option.externalId ?? ""} `}
					rules={requireRule()}
				/>
			</>
		</>
	);
};
