import { html, LitElement } from "lit";
import $ from "jquery";
import Selectize from "@selectize/selectize";
import { v4 as uuidv4 } from "uuid";
import equal from "fast-deep-equal";

window.jQuery = window.$ = $;

class SelectizeElement extends LitElement {
	static get properties() {
		return {
			staticOptions: {
				type: Array, hasChanged: (oldVal, newVal) => !equal(oldVal, newVal)
			},
			currentSelections: {
				type: Array, hasChanged: (oldVal, newVal) => !equal(oldVal, newVal)
			},
			isLazy: { type: Boolean },
			placeholderText: { type: String },
			nullValueEnabled: { type: Boolean },
			isReadOnly: { type: Boolean },
			hasFocus: { type: Boolean },
			multiSelect: { type: Boolean },
			lazyLoadResult: {
				type: Object, hasChanged: (oldVal, newVal) => {
					return !newVal || oldVal.requestId !== newVal.requestId;
				}
			} // Should contain fetched options and requestId
		};
	}

	createRenderRoot() {
		return this;
	}

	constructor() {
		super();

		this._selectizeElement = null;

		this.staticOptions = [];
		this.isLazy = false;
		this.isReadOnly = false;
		this.nullValueEnabled = false;
		this.hasFocus = false;
		this.currentSelections = [];
		this.placeholderText = "";
		this.multiSelect = true;

		this.lazyLoadResult = null;
		this._lazyLoadRequest = null; // Contains callback function and requestId
		this._dropdownIsOpen = false;
	}

	render() {
		return html` <select class="selectize-container" /> `;
	}

	firstUpdated(changedProperties) {
		// Check the possible placeholder from options.
		// Value for placeholder should be empty string, label of this option will be the placeholder.
		const {
			options,
			selection,
			placeholderText
		} = this._removePlaceholderOption(this.currentSelections, this.staticOptions);
		this._currentOptionsAsMap = this._optionsListToMap(options);

		const config = {
			valueField: "value",
			labelField: "label",
			searchField: ["value", "label"],
			options: options,
			placeholder: placeholderText,
			items: selection ? [selection] : [],
			maxItems: this.multiSelect ? null : 1,
			onDropdownOpen: this._onDropdownOpen.bind(this),
			onDropdownClose: this._onDropdownClose.bind(this),
			openOnFocus: this.multiSelect,
			dropdownParent: "body",
			dropdownDirection: "auto",
			onLoad: () => {
				this._selectizeElement.positionDropdown();
			},
			onType: () => {
				this._selectizeElement.positionDropdown();
			},
			plugins: this._configurePlugins()
		};

		if (this.isLazy) {
			config.load = this._onLazyLoadRequested.bind(this);
			// Load options focus when in lazy mode.
			config.onFocus = () => {
				this._selectizeElement.clearOptions();
				this._selectizeElement.load((cb) => this._onLazyLoadRequested("", cb));
			};
		}

		const container = this.querySelector("select.selectize-container");
		const select = $(container).selectize(config);
		this._selectizeElement = select[0].selectize;
	}

	_configurePlugins() {
		const that = this;
		return {
			custom_remove_button: {
				enabled: this.nullValueEnabled || this.multiSelect, onSelectionRemoved: (item) => {
					const newSelections = that.currentSelections.filter((sel) => sel.toString() !== item.value.toString());
					that._dispatchSelectionChangedEvent(newSelections);
				}
			}
		};
	}

	// Bug in selectize.js causes onDropdownClose triggered twice.
	// Workaround is keep the actual state of dropdown on variable.
	_onDropdownOpen() {
		this._dropdownIsOpen = true;
	}

	_onDropdownClose() {
		if (!this._selectizeElement || !this._dropdownIsOpen) return;
		this._dropdownIsOpen = false;
		const items = this._selectizeElement.items;
		this.currentSelections = items;
		this._dispatchSelectionChangedEvent(items);
	}

	_dispatchSelectionChangedEvent(selections) {
		const selectionsWithLabels = (selections || [])
			.map((selection) => {
				const selectedOption = this._currentOptionsAsMap.get(selection.toString());
				if (selectedOption == null) {
					console.error("selectize", `Option for selection '${selection} not found`, "Options:", this._currentOptionsAsMap);
					return null;
				}
				return {
					value: selectedOption.value, label: selectedOption.label
				};
			})
			.filter((sel) => sel != null);

		this.dispatchEvent(new CustomEvent("selectionChanged", {
			detail: selectionsWithLabels
		}));
	}

	_onLazyLoadRequested(query, callback) {
		const requestId = uuidv4();
		this._lazyLoadRequest = {
			callback, requestId
		};
		this._setLoadingClass(true);
		this.dispatchEvent(new CustomEvent("lazyLoadRequested", {
			detail: {
				query, requestId
			}
		}));
	}

	updated(changedProperties) {
		if (!this._selectizeElement) return;

		if (changedProperties.has("staticOptions")) {
			const {
				options,
				selections,
				placeholderText
			} = this._removePlaceholderOption(this.currentSelections, this.staticOptions);
			this._currentOptionsAsMap = this._optionsListToMap(this.staticOptions);
			this._setSelectedItems([]);
			this._selectizeElement.clearOptions();
			this._selectizeElement.addOption(options);
			this._setSelectedItems(selections);
			this._setPlaceholder(placeholderText);
		}

		if (changedProperties.has("currentSelections")) {
			const {
				selections,
				placeholderText
			} = this._removePlaceholderOption(this.currentSelections, this.staticOptions);
			this._setSelectedItems(selections);
			this._setPlaceholder(placeholderText);
		}

		if (changedProperties.has("lazyLoadResult")) {
			const currentRequestId = this._lazyLoadRequest ? this._lazyLoadRequest.requestId : null;
			if (this.lazyLoadResult && this.lazyLoadResult.requestId === currentRequestId) {
				this._selectizeElement.clearOptions(false);
				const fetchedOptions = this.lazyLoadResult.options;
				this._lazyLoadRequest.callback(fetchedOptions);
				this._setLoadingClass(false);
				this._currentOptionsAsMap = this._optionsListToMap(fetchedOptions);
			}
		}
		if (changedProperties.has("isReadOnly")) {
			if (this.isReadOnly) this._selectizeElement.disable(); else this._selectizeElement.enable();
		}
		if (changedProperties.has("hasFocus")) {
			if (this.hasFocus) {
				this._selectizeElement.focus();
				this._selectizeElement.open();
			}
		}
	}

	_setLoadingClass(isLoading) {
		const selectizeControl = this.querySelector(".selectize-control");
		selectizeControl.classList.toggle("loading", isLoading);
	}

	_optionsListToMap(optionsList) {
		return new Map(optionsList.map((obj) => [obj.value.toString(), obj]));
	}

	_setSelectedItems(selectedItems) {
		if (!this._selectizeElement) return;
		if (selectedItems) {
			this._selectizeElement.setValue(selectedItems, true);
		} else {
			this._selectizeElement.clear(true);
		}
	}

	_setPlaceholder(placeholder) {
		this._selectizeElement.settings.placeholder = placeholder;
		this._selectizeElement.updatePlaceholder();
	}

	_removePlaceholderOption(selections, options) {
		const optionsWithoutPlaceholder = options
			.filter(({ value }) => value !== "")
			.map((opt) => ({ ...opt }));

		const firstSelection = selections ? selections[0] : null;
		if (!firstSelection || firstSelection === "") {
			const placeholderOption = options.find(({ value }) => value === "");
			return {
				options: optionsWithoutPlaceholder,
				placeholderText: placeholderOption ? placeholderOption.label : this.placeholderText,
				selections: []
			};
		}
		return {
			options: optionsWithoutPlaceholder, placeholderText: this.placeholderText, selections: selections
		};
	}
}

// Fix issue causing container to scroll leftmost position when dropdown is opened.
Selectize.prototype.focus = function() {
	const self = this;
	if (self.isDisabled) return self;

	self.ignoreFocus = true;
	setTimeout(function() {
		self.ignoreFocus = false;
		self.onFocus();
		self.$control_input[0].focus();
	}, 0);
	return self;
};

// Implement automatic up/down positioning of dropdown
if (!Selectize.prototype.positionDropdownOriginal) {
	Selectize.prototype.positionDropdownOriginal = Selectize.prototype.positionDropdown;
	Selectize.prototype.positionDropdown = function() {
		const $control = this.$control;
		const offset = this.settings.dropdownParent === "body" ? $control.offset() : $control.position();
		const documentHeight = document.documentElement.getBoundingClientRect().height;
		const offsetTopRelative = offset.top / documentHeight;
		if (this.settings.dropdownDirection === "auto" && offsetTopRelative > 0.5) {
			this.$dropdown.css({
				width: $control.outerWidth(), top: offset.top - this.$dropdown.outerHeight(true), left: offset.left
			});
		} else {
			Selectize.prototype.positionDropdownOriginal.apply(this, arguments);
		}
	};
}

Selectize.define("custom_remove_button", function(options) {
	const self = this;

	function htmlToElement(html) {
		const template = document.createElement("template");
		html = html.trim(); // Never return a text node of whitespace as the result
		template.innerHTML = html;
		return template.content.firstChild;
	}

	function createRemoveButton(item) {
		const button = htmlToElement(`<i class="remove-btn fas fa-times"/>`);
		if (!options.enabled) {
			button.style.display = "none";
		}
		button.addEventListener("click", (event) => {
			if (self.isLocked) return;
			self.clear(true);
			self.blur();
			self.updatePlaceholder();
			options.onSelectionRemoved(item);
			event.stopPropagation();
			event.preventDefault()
			return false;
		});
		button.addEventListener("mousedown", (event) => {
			event.stopPropagation();
			event.preventDefault();
			return false;
		});
		return button;
	}

	self.setup = (function() {
		const original = self.setup;
		return function() {
			// override the item rendering method to add the button to each
			const renderItem = self.settings.render.item;
			self.settings.render.item = function(data) {
				const container = htmlToElement(`<div class="selectize-item-container"/>`);
				const itemHtml = renderItem.apply(self, arguments);
				container.appendChild(htmlToElement(itemHtml));
				container.appendChild(createRemoveButton(data));
				return container;
			};
			original.apply(self, arguments);

			// Open dropdown on click.
			self.$control.on("click", function() {
				self.open();
			});
		};
	})();
});

customElements.define("selectize-js", SelectizeElement);
