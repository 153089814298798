import { createContext } from "react";
import { AavoDataGridProps } from "src/components/common/dataGrid/AavoDataGrid.tsx";

export interface AavoDataGridContextValue
	extends Pick<
		AavoDataGridProps<object>,
		"refreshData" | "actionBarComponents" | "actionBarMenuComponents" | "actionBarMenuButtonRef"
	> {}

export const AavoDataGridContext = createContext<AavoDataGridContextValue | undefined>(undefined);
