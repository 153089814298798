import { useController, UseControllerProps } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { SxProps, Theme } from "@mui/material/styles";
import { AavoRichTextEditor } from "src/components/common/inputFields/AavoRichTextEditor.tsx";

export interface FormRichTextEditorProps<TFieldValues extends object> {
	control: UseControllerProps<TFieldValues>["control"];
	name: UseControllerProps<TFieldValues>["name"];
	label?: string;
	sx?: SxProps<Theme>;
}

export const FormRichTextEditor = <TFieldValues extends object>({
	control,
	name,
	...other
}: FormRichTextEditorProps<TFieldValues>) => {
	const { field } = useController({
		name,
		control,
	});

	const transformedField = {
		...field,
		ref: undefined
	}

	return <AavoRichTextEditor {...transformedField} {...other} />;
};
