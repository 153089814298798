import {
	booleanColumn,
	coloredBooleanColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	singleSelectColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getControlChartTypeLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/controlChartType.ts";
import { getControlChartProcessRoleLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/controlChartProcessRole.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { getControlChartTimeUnitLabels } from "src/api/generated/io/aavo/applications/db/postgres/enums/controlChartTimeUnit.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart.ts";
import { ControlChartDataGridApi_GridData } from "src/api/generated/spc/controlChart/api/controlChartDataGridApi.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
	faBars,
	faChartLine,
	faClone,
	faCloudDownloadAlt,
	faCogs,
	faDatabase,
	faExchangeAlt,
	faSquareRootAlt,
	faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { useConfirmWithInputDialog } from "src/components/common/dialogs/confirmDialog/useConfirmWithInputDialog.tsx";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ControlChartInspectingView } from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingView.tsx";
import { openLegacyControlChartLimitsDataView } from "src/components/views/legacy/legacyViewAdapters.ts";
import {
	askInputAndOpenControlChartCapabilityView,
	updateControlChartsChartGroup,
} from "src/components/views/spc/controlChart/utils.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { ControlChartsDataExportForm } from "src/components/views/spc/basedata/controlCharts/ControlChartsDataExportForm.tsx";
import {
	CloneChartForm,
	CloneControlChartFormType,
} from "src/components/views/spc/basedata/controlCharts/CloneChartForm.tsx";
import { ControlChartActionApi } from "src/api/generated/spc/controlChart/api/controlChartActionApi";
import { ControlChartEditApi } from "src/api/generated/spc/controlChart/api/controlChartEditApi.ts";
import {
	ControlChartForm,
	ControlChartFormProps,
} from "src/components/views/spc/basedata/controlCharts/form/ControlChartForm.tsx";
import { ControlChartRecordsDataGrid } from "src/components/views/spc/controlChart/ControlChartRecordsDataGrid.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import React from "react";
import { AavoDataGridRowContextMenuParams } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { ControlChartSpecialCauseTestsForm } from "src/components/views/spc/basedata/controlCharts/ControlChartSpecialCauseTestsForm.tsx";

export interface ControlChartsDataGridProps {
	formProps: Partial<ControlChartFormProps>;
	fetchData: (props: {
		searchQuery: string;
		showDisabledCharts: boolean;
	}) => Promise<ControlChartDataGridApi_GridData>;
	contextMenuExtraComponents?: (params: AavoDataGridRowContextMenuParams<ControlChart>) => React.ReactNode;
	refreshRef: RefreshableElementRef;
	cloneProps: CloneControlChartFormType;
}

export const ControlChartsDataGrid = ({
	formProps,
	fetchData,
	contextMenuExtraComponents,
	refreshRef,
	cloneProps,
}: ControlChartsDataGridProps) => {
	const showConfirmWithInputDialog = useConfirmWithInputDialog();
	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();
	const openLegacyView = useOpenLegacyView();

	return (
		<ClientSideDataGridModel
			refreshRef={refreshRef}
			gridId={"4BABB64308A75D9B"}
			fetchData={fetchData}
			getRows={(data) => data.charts}
			getRowId={(row) => row.id}
			initialParams={{
				searchQuery: "",
				showDisabledCharts: false,
			}}
			render={({
				data: { calendarOptions },
				onlySelectedRow,
				refreshData,
				dataGridProps,
				currentParams,
			}) => {
				return (
					<CrudDataGrid<ControlChart>
						columns={[
							textColumn({
								field: "id",
								headerName: i18n.t("id"),
								width: 60,
							}),
							textColumn({
								field: "externalId",
								headerName: i18n.t("external_id"),
								width: 130,
							}),
							textColumn({
								field: "name",
								headerName: i18n.t("name"),
								width: 200,
							}),
							textColumn({
								field: "description",
								headerName: i18n.t("description"),
							}),
							enumColumn({
								field: "chartType",
								headerName: i18n.t("type"),
								enumLabels: getControlChartTypeLabels(),
							}),
							coloredBooleanColumn({
								field: "enabled",
								headerName: i18n.t("enabled"),
							}),
							floatColumn({
								field: "lsl",
								headerName: i18n.t("lsl"),
							}),
							floatColumn({
								field: "target",
								headerName: i18n.t("target.spc"),
							}),
							floatColumn({
								field: "usl",
								headerName: i18n.t("usl"),
							}),
							integerColumn({
								field: "subgroupSize",
								headerName: i18n.t("subgroup_size"),
								width: 120,
							}),
							singleSelectColumn({
								field: "calendarId",
								headerName: i18n.t("calendar"),
								valueOptions: calendarOptions.map((option) => ({
									value: option.id,
									label: option.name,
								})),
								width: 120
							}),
							enumColumn({
								field: "timeUnit",
								headerName: i18n.t("time_unit"),
								enumLabels: getControlChartTimeUnitLabels(),
							}),
							booleanColumn({
								field: "timeBasedMeasurements",
								headerName: i18n.t("time_based"),
								width: 120,
							}),
							enumColumn({
								field: "processRole",
								headerName: i18n.t("process_role.spc"),
								enumLabels: getControlChartProcessRoleLabels(),
							}),
						]}
						remove={
							onlySelectedRow == null ?
								{ type: "disabled" }
							:	{
									type: "enabled",
									confirm: false,
									action: async () => {
										if (onlySelectedRow == null) return;

										const confirmed = await showConfirmWithInputDialog({
											title: i18n.t("delete_control_chart_confirmation_title", {
												chart_name: onlySelectedRow?.name,
											}),
											message: i18n.t("delete_control_chart_confirmation_text"),
											inputToMatch: onlySelectedRow?.name,
											submitLabel: i18n.t("delete"),
										});
										if (!confirmed) return;
										else {
											await ControlChartEditApi.removeControlChart({
												controlChartId: onlySelectedRow.id,
											});
											await refreshData();
										}
									},
								}
						}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef("CONTROL_CHART", onlySelectedRow?.id)}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (value) => {
										await refreshData({ searchQuery: value });
									}}
								/>
							</>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showDisabledCharts"}
								label={i18n.t("show_disabled_charts")}
								defaultChecked={currentParams.showDisabledCharts}
								onChange={async (checked) => {
									await refreshData({ showDisabledCharts: checked });
								}}
							/>,
						]}
						rowContextMenuComponents={(params) => {
							const { row, allSelectedRows, onlySingleRowSelected } = params;
							return [
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"chartButton"}
										icon={faChartLine}
										label={i18n.t("chart")}
										onClick={() => {
											openDialog(() => ({
												title: i18n.t("control_chart"),
												content: (
													<ControlChartInspectingView controlChartId={row.id} />
												),
											}));
										}}
									/>
								),
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"dataButton"}
										icon={faDatabase}
										label={i18n.t("data.spc")}
										onClick={() => {
											openDialog({
												title: row.name,
												content: (
													<ControlChartRecordsDataGrid controlChartId={row.id} />
												),
											});
										}}
									/>
								),
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"capabilityButton"}
										icon={faSquareRootAlt}
										label={i18n.t("capability")}
										onClick={async () => {
											await askInputAndOpenControlChartCapabilityView({
												showInputDialog,
												openLegacyView,
												controlChartId: row.id,
												chartName: row.name,
											});
										}}
									/>
								),
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"controlLimitsButton"}
										icon={faBars}
										label={i18n.t("control_limits")}
										onClick={() => {
											openLegacyControlChartLimitsDataView({
												openLegacyView,
												controlChartId: row.id,
											});
										}}
									/>
								),
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"moveToGroupButton"}
										icon={faExchangeAlt}
										label={i18n.t("move_to_group")}
										onClick={async () => {
											await updateControlChartsChartGroup({
												showInputDialog,
												controlChartId: row.id,
											});
											await refreshData();
										}}
									/>
								),
								<AsyncMenuButton
									key={"exportDataButton"}
									icon={faCloudDownloadAlt}
									label={i18n.t("export_data")}
									onClick={async () => {
										openFormOnDialog({
											openDialog,
											title: i18n.t("export_data"),
											size: "sm",
											component: ControlChartsDataExportForm,
											props: {
												controlChartIds: allSelectedRows.map((r) => r.id),
											},
											confirmCloseIfEdited: false,
										});
									}}
								/>,
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"clearDataButton"}
										icon={faTimes}
										label={i18n.t("clear_data")}
										onClick={async () => {
											const confirmed = await showConfirmWithInputDialog({
												title: i18n.t("clear_data_confirmation_title", {
													chart_name: row.name,
												}),
												message: i18n.t("clear_data_confirmation_text"),
												inputToMatch: row.name,
												submitLabel: i18n.t("delete"),
											});
											if (!confirmed) return;
											else {
												await ControlChartActionApi.clearControlChartData({
													controlChartId: row.id,
												});
												await refreshData();
											}
										}}
									/>
								),
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"selectSpecialCauseTestButton"}
										icon={faCogs}
										label={i18n.t("select_special_cause_tests")}
										onClick={async () => {
											openDialog(({ closeDialog }) => ({
												size: "md",
												title: i18n.t("select_special_cause_tests"),
												content: (
													<ControlChartSpecialCauseTestsForm
														controlChart={row}
														onCompleted={async () => {
															await closeDialog();
														}}
														controlChartsRefreshRef={refreshRef}
													/>
												),
											}));
										}}
									/>
								),
								onlySingleRowSelected && (
									<AsyncMenuButton
										key={"cloneChartButton"}
										icon={faClone}
										label={i18n.t("clone")}
										onClick={async () => {
											openDialog(({ closeDialog }) => ({
												size: "sm",
												title: i18n.t("clone"),
												content: (
													<CloneChartForm
														sourceControlChartId={row.id}
														onCompleted={async () => {
															await closeDialog();
															await refreshData();
														}}
														cloneFormType={cloneProps}
													/>
												),
											}));
										}}
									/>
								),
								contextMenuExtraComponents != null && contextMenuExtraComponents(params),
							];
						}}
						form={{
							editEnabled: true,
							addRowEnabled: true,
							dialogTitle: i18n.t("control_chart"),
							dialogSize: "lg",
							component: ({ row, ...other }) => (
								<ControlChartForm
									controlChartId={row?.id ?? null}
									{...formProps}
									{...other}
								/>
							),
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
