import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { ControlChartEditApi } from "src/api/generated/spc/controlChart/api/controlChartEditApi.ts";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import i18n from "i18next";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface ControlChartSpecialCauseTestsFormProps extends FormCommonProps<number> {
	controlChart: ControlChart;
	controlChartsRefreshRef: RefreshableElementRef;
}

export const ControlChartSpecialCauseTestsForm = ({
	controlChart,
	controlChartsRefreshRef,
	onFormEdited,
	onCompleted,
}: ControlChartSpecialCauseTestsFormProps) => {
	const xTestIndexList: Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8> = [1, 2, 3, 4, 5, 6, 7, 8];
	const rTestIndexList: Array<1 | 2 | 3 | 4> = [1, 2, 3, 4];
	return (
		<AavoForm<ControlChart, number>
			defaultValues={controlChart}
			render={({ control }) => (
				<>
					<FormSection
						label={i18n.t("x_bar_chart")}
						sx={{
							gap: "0.15rem",
						}}
					>
						{xTestIndexList.map((xTestIndex) => (
							<FormCheckbox
								control={control}
								name={`scTestX_${xTestIndex}Enabled`}
								label={i18n.t(`spc_sc_test_${xTestIndex}_description`)}
							/>
						))}
					</FormSection>
					<FormSection
						label={i18n.t("deviation_chart")}
						sx={{
							gap: "0.15rem",
						}}
					>
						{rTestIndexList.map((rTestIndex) => (
							<FormCheckbox
								control={control}
								name={`scTestR_${rTestIndex}Enabled`}
								label={i18n.t(`spc_sc_test_${rTestIndex}_description`)}
							/>
						))}
					</FormSection>
				</>
			)}
			submit={async (values) => {
				const result = await ControlChartEditApi.update({ controlChart: values });
				await controlChartsRefreshRef.refresh();
				return result;
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
		/>
	);
};
