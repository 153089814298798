import { OmegakeittiotMachineToolType } from "src/api/generated/tenants/omegakeittiot/machineTools/model/omegakeittiotMachineToolType.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { OmegakeittiotMachineToolsApi } from "src/api/generated/tenants/omegakeittiot/machineTools/omegakeittiotMachineToolsApi.ts";
import { OmegakeittiotMachineToolInstructionLine } from "src/api/generated/tenants/omegakeittiot/machineTools/model/omegakeittiotMachineToolInstructionLine.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCloudDownloadAlt } from "@fortawesome/pro-regular-svg-icons";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";

export interface OmegakeittiotMachineToolInstructionGenerationDataGridProps {
	shopOrderBatchId: number;
	machineType: OmegakeittiotMachineToolType;
}

interface GridDataWithIndex extends OmegakeittiotMachineToolInstructionLine {
	lineId: number;
}

interface FetchParams {
	searchQuery: string;
}

export const OmegakeittiotMachineToolInstructionGenerationDataGrid = ({
	shopOrderBatchId,
	machineType,
}: OmegakeittiotMachineToolInstructionGenerationDataGridProps) => {
	return (
		<ClientSideDataGridModel<GridDataWithIndex[], GridDataWithIndex, FetchParams>
			gridId={"BC2D1AEF0FA38655"}
			fetchData={async ({ searchQuery }) => {
				const data = await OmegakeittiotMachineToolsApi.getMachineToolInstructionViewData({
					shopOrderBatchId: shopOrderBatchId,
					machineType: machineType,
					searchQuery: searchQuery,
				});
				return data.map((line, index) => ({
					lineId: index,
					...line,
				}));
			}}
			getRows={(data) => data}
			getRowId={(row) => row.lineId}
			initialParams={{
				searchQuery: "",
			}}
			render={({ dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<GridDataWithIndex>
						columns={[
							integerColumn({
								field: "shopOrderBatchId",
								headerName: "LotNumber",
							}),
							textColumn({
								field: "article",
								headerName: "Article",
							}),
							textColumn({
								field: "description",
								headerName: "Description",
							}),
							textColumn({
								field: "note",
								headerName: "Note",
							}),
							floatColumn({
								field: "qty",
								headerName: "Qty",
							}),
							textColumn({
								field: "program",
								headerName: "Program",
							}),
							textColumn({
								field: "material",
								headerName: "Material",
							}),
							floatColumn({
								field: "thickness",
								headerName: "Thickness",
							}),
							floatColumn({
								field: "height",
								headerName: "Height",
							}),
							floatColumn({
								field: "width",
								headerName: "Width",
							}),
							floatColumn({
								field: "grainDirection",
								headerName: "GrainDirection",
							}),
							floatColumn({
								field: "margin",
								headerName: "Margin",
							}),
							floatColumn({
								field: "priority",
								headerName: "Priority",
							}),
							textColumn({
								field: "upperCode0",
								headerName: "UpperCode0",
							}),
							textColumn({
								field: "upperCode1",
								headerName: "UpperCode1",
							}),
							textColumn({
								field: "up",
								headerName: "Up",
							}),
							textColumn({
								field: "down",
								headerName: "Down",
							}),
							textColumn({
								field: "left",
								headerName: "Left",
							}),
							textColumn({
								field: "right",
								headerName: "Right",
							}),
							textColumn({
								field: "programPath",
								headerName: "ProgramPath",
							}),
						]}
						actionBarComponents={
							<>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (input) => {
										await refreshData({ searchQuery: input });
									}}
								/>
								<AsyncButton
									icon={faCloudDownloadAlt}
									label={"Lataa työstöohjelma"}
									variant={"outlined"}
									onClick={async () => {
										const fileHandle = await OmegakeittiotMachineToolsApi.generateInstructionFile({
											shopOrderBatchId: shopOrderBatchId,
											machineType: machineType,
										});
										downloadFile(fileHandle);
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
