import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";
import { SsaCustomerOrderDegreeOfReadinessApi } from "src/api/generated/tenants/ssa/sales/ssaCustomerOrderDegreeOfReadinessApi.ts";

export const ssaCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			reclamationsEnabled: true,
			configuratorEnabled: false,
			defaultCustomerType: "COMPANY",
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	erp: {
		sales: {
			printCustomerOrderDegreeOfReadinessReport:
				SsaCustomerOrderDegreeOfReadinessApi.printCustomerOrderDegreeOfReadinessReport,
		},
	},
};
