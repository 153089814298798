import { AavoDialog, AavoDialogProps } from "src/components/common/dialogs/AavoDialog.tsx";
import i18n from "i18next";
import { ErrorText } from "src/components/common/errors/ErrorText.tsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { OpenErrorReportDialogButton } from "src/sentry/OpenErrorReportDialogButton";
import { LoggedError } from "src/errorHandling/errorLogging.ts";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ErrorDialogProps
	extends Omit<AavoDialogProps, "title" | "actions" | "content" | "open" | "onClose"> {
	error: LoggedError;
	closeDialog: () => void;
}

export const ErrorDialog = ({ error, size = "sm", closeDialog, ...other }: ErrorDialogProps) => {
	return (
		<AavoDialog
			onClose={closeDialog}
			size={size}
			title={i18n.t("error")}
			actions={
				<>
					{error.treatAsError && error.sentryEventId && (
						<OpenErrorReportDialogButton
							eventId={error.sentryEventId}
							onClick={() => {
								closeDialog();
							}}
						/>
					)}
					<Button
						variant={"contained"}
						startIcon={<FontAwesomeIcon icon={faCheck} />}
						onClick={() => {
							closeDialog();
						}}
					>
						<Typography
							variant={"button"}
							sx={{
								flex: 1,
								textAlign: "left",
							}}
						>
							{i18n.t("close")}
						</Typography>
					</Button>
				</>
			}
			{...other}
		>
			<ErrorText error={error} showReportButton={false} />
		</AavoDialog>
	);
};
