import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { ConfiguratorManagingApi } from "src/api/generated/erp/configurator/management/api/configuratorManagingApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import i18n from "i18next";
import { booleanColumn, enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty.ts";
import { getConfigurationPropertyDatatypeLabels } from "src/api/generated/erp/db/types/enums/configurationPropertyDatatype.ts";
import { isNullOrBlank } from "src/utils/strings.tsx";
import { ConfiguratorPropertyForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/properties/ConfiguratorPropertyForm.tsx";
import { ConfiguratorPropertyEditApi } from "src/api/generated/erp/configurator/management/api/configuratorPropertyEditApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

export interface ConfiguratorPropertiesDataGridProps {
	productFamilyVersionId: number;
	editingDisabled?: boolean;
}

export const ConfiguratorPropertiesDataGrid = ({
	productFamilyVersionId,
	editingDisabled,
}: ConfiguratorPropertiesDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () =>
			ConfiguratorManagingApi.getProductFamilyVersionConfiguratorProperties({
				productFamilyVersionId,
			}),
		getRowId: (row) => row.configurationPropertyId,
		gridId: "9EF437407C7AAB09",
		initialParams: {},
	});
	return (
		<ControlledAsyncCrudDataGrid<ConfigurationProperty>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 250,
				}),
				textColumn({
					field: "title",
					headerName: i18n.t("view_name"),
					width: 250,
				}),
				enumColumn({
					field: "datatype",
					headerName: i18n.t("type"),
					enumLabels: getConfigurationPropertyDatatypeLabels(),
					width: 150,
				}),
				booleanColumn({
					field: "visibleOnPrintouts",
					headerName: i18n.t("visible_on_printouts"),
					width: 150,
				}),
				textColumn({
					field: "valueFrom" as any,
					headerName: i18n.t("value"),
					valueGetter: (_, row) =>
						row.valueFromComponentId != null ? i18n.t("from_component") : i18n.t("computed"),
					width: 200,
				}),
				booleanColumn({
					field: "hasSubConfigurationValueScript" as any,
					headerName: i18n.t("computed_on_sub_configurator"),
					valueGetter: (_, row) => !isNullOrBlank(row.subConfigurationValueScript),
					width: 200,
				}),
			]}
			form={{
				addRowEnabled: !editingDisabled,
				editEnabled: true,
				dialogTitle: i18n.t("property"),
				dialogSize: "xl",
				component: ({ row, onCompleted, onFormEdited }) => (
					<ConfiguratorPropertyForm
						productFamilyVersionId={productFamilyVersionId}
						configuratorPropertyId={row?.configurationPropertyId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
						disabled={editingDisabled}
					/>
				),
			}}
			remove={
				!editingDisabled &&
				(({ items }) =>
					ConfiguratorPropertyEditApi.deleteProperties({
						propertyIds: items.map((item) => item.configurationPropertyId),
					}))
			}
			actionBarComponents={
				<>
					<AsyncButton
						icon={faAngleUp}
						disabled={!onlySelectedRow || editingDisabled}
						onClick={async () => {
							await ConfiguratorPropertyEditApi.moveProperty({
								propertyId: onlySelectedRow!.configurationPropertyId,
								direction: "UP",
							});
							await refreshData();
						}}
					/>
					<AsyncButton
						icon={faAngleDown}
						disabled={!onlySelectedRow || editingDisabled}
						onClick={async () => {
							await ConfiguratorPropertyEditApi.moveProperty({
								propertyId: onlySelectedRow!.configurationPropertyId,
								direction: "DOWN",
							});
							await refreshData();
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
