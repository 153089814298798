import * as firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { getFirebaseOptions, getFirebaseVapidKey } from "src/config/appConfig.ts";
import {
	Messaging,
	deleteToken,
	getMessaging,
	getToken,
	MessagePayload,
	onMessage,
	isSupported as firebaseIsSupported,
} from "firebase/messaging";
import { setInitialNotificationAction } from "./initialNotificationAction";
import { LegacyPushAction } from "./legacyPushAction";

export interface PushNotificationCallbacks {
	legacyAavoActionReceived: (event: LegacyPushAction) => void;
}

const firebaseConfig = getFirebaseOptions();
const vapidKey = getFirebaseVapidKey();

let messagingCache: Messaging | null = null;

const initializeMessaging = async (): Promise<Messaging | null> => {
	if (messagingCache != null) return messagingCache;

	const supported = await firebaseIsSupported();
	if (!supported) return null;

	const firebaseApp = firebase.initializeApp(firebaseConfig);
	messagingCache = getMessaging(firebaseApp);
	return messagingCache;
};

export const deletePushNotificationRegistrations = async () => {
	const messaging = await initializeMessaging();
	if (messaging == null) return;

	await deleteToken(messaging);
};

/**
 * This function should be called in very early stage on startup.
 * Client should begin listening service worker messages immediately,
 * because SW may open a new client and send message for it just after that.
 */
export const setupInitialBackgroundMessageEventHandler = () => {
	if (!("serviceWorker" in navigator)) return null;

	navigator.serviceWorker.onmessage = initialReceivedMessageHandler;
};

/**
 * Initializes firebase messaging and returns the registration token.
 */
export const setupFirebaseMessaging = async (
	callbacks: PushNotificationCallbacks,
): Promise<string | null> => {
	try {
		if (!("serviceWorker" in navigator)) return null;

		const serviceWorker = await navigator.serviceWorker.ready;

		const messaging = await initializeMessaging();
		if (messaging == null) return null;

		navigator.serviceWorker.removeEventListener("message", initialReceivedMessageHandler);
		navigator.serviceWorker.onmessage = (message) => {
			handleReceivedMessageFromServiceWorker(message, callbacks.legacyAavoActionReceived);
		};

		const hasNotificationPermission = await askNotificationPermission();
		if (!hasNotificationPermission) return null;

		console.info("Notification permission granted.");

		const token = await fetchRegistrationToken(serviceWorker);
		if (token == null) return null;

		onMessage(
			messaging,
			async (payload) => await onForegroundMessage(serviceWorker, payload),
		);

		return token;
	} catch (err) {
		console.error("Firebase messaging initialization failed", err);
		return null;
	}
};

async function fetchRegistrationToken(
	serviceWorker: ServiceWorkerRegistration,
): Promise<string | null> {
	try {
		const messaging = await initializeMessaging();
		if (messaging == null) return null;

		return await getToken(messaging, {
			vapidKey: vapidKey,
			serviceWorkerRegistration: serviceWorker,
		});
	} catch (err) {
		console.error("An error occurred while retrieving cloud messaging token. ", err);
		return null;
	}
}

const askNotificationPermission = async () => {
	if (!("Notification" in window)) return false;
	const permission = await Notification.requestPermission();
	if (permission !== "granted") {
		console.log(`Notification permission not granted (was ${permission})`);
		return false;
	}
	return true;
};

async function onForegroundMessage(
	serviceWorker: ServiceWorkerRegistration,
	payload: MessagePayload,
) {
	try {
		const contentStr = payload.data?.content;
		if (!contentStr) return;
		const content = JSON.parse(contentStr);

		if (document.visibilityState === "visible")
			await serviceWorker.showNotification(content.notification.title, content.notification);
	} catch (err) {
		console.error("Failed to send foreground message", err);
	}
}

const initialReceivedMessageHandler = (message: any) => {
	handleReceivedMessageFromServiceWorker(message, (actionEvent) => {
		setInitialNotificationAction(actionEvent);
	});
};

function handleReceivedMessageFromServiceWorker(
	message: any,
	handleLegacyAavoAction: (actionEvent: LegacyPushAction) => void,
) {
	const messageContent = message.data;
	if (messageContent.action === "EXECUTE_AAVO_ACTION") {
		// This is basically click action from background push notification.
		handleLegacyAavoAction({
			actionId: uuidv4(),
			action: messageContent.aavoAction,
		});
	}
}
