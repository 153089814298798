import {
	ClientSideDataGridModel,
	ClientSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { CrudDataGrid, CrudDataGridApi } from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
	SitePartRevisionOperationsDataGridApi,
	SitePartRevisionOperationsDataGridApi_Data,
} from "src/api/generated/erp/parts/sitePart/api/sitePartRevisionOperationsDataGridApi";
import {
	autoCompleteSingleSelectColumn,
	floatColumn,
	integerColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { concatWithPipe, isNullOrBlank } from "src/utils/strings.tsx";
import { AavoDataGridApi } from "src/components/common/dataGrid/AavoDataGridApi.ts";
import { associate, filterNulls } from "src/utils/arrayUtils.ts";
import { PartRevisionOperationView } from "src/api/generated/erp/db/types/tables/partRevisionOperationView.ts";
import { GridBooleanCell } from "@mui/x-data-grid-pro";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { faLaptopCode } from "@fortawesome/pro-regular-svg-icons";
import { AavoIconButton } from "src/components/common/buttons/AavoIconButton";
import { useRef } from "react";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";

export interface SitePartRevisionOperationsDataGridProps {
	partRevisionId: number;
	dataDirtyStateChanged?: DataDirtyStateChangeHandler;
}

export const SitePartRevisionOperationsDataGrid = (props: SitePartRevisionOperationsDataGridProps) => {
	const { partRevisionId } = props;
	return (
		<ClientSideDataGridModel
			fetchData={() => SitePartRevisionOperationsDataGridApi.getData({ partRevisionId })}
			gridId={"9ADC38DC6632BEF6"}
			getRows={(data) => data.partRevisionOperations}
			initialParams={{}}
			getRowId={(row) => row.partRevisionOperationId!}
			render={(params) => <DataGrid {...params} {...props} />}
		/>
	);
};

interface DataGridProps
	extends SitePartRevisionOperationsDataGridProps,
		ClientSideDataGridModelRenderProps<
			SitePartRevisionOperationsDataGridApi_Data,
			PartRevisionOperationView,
			Record<string, never>
		> {}

const DataGrid = ({
	dataGridProps,
	getApi,
	onlySelectedRow,
	dataDirtyStateChanged,
	data: { operationOptions, partRevision },
}: DataGridProps) => {
	const showFormDialog = useFormInput();
	const crudDataGridApiRef = useRef<CrudDataGridApi | null>(null);

	const { partRevisionId, partRevisionState, partIsConfigurable } = partRevision;
	const editable = partRevisionState !== "RELEASED";
	const operationsByIds = associate(
		operationOptions,
		(op) => op.operationId,
		(op) => op,
	);
	return (
		<CrudDataGrid<PartRevisionOperationView>
			crudDataGridApiRef={crudDataGridApiRef}
			dataDirtyStateChanged={dataDirtyStateChanged}
			editMode={"cell"}
			columns={[
				integerColumn({
					field: "position",
					headerName: i18n.t("number_shortened"),
				}),
				autoCompleteSingleSelectColumn({
					field: "operationId",
					headerName: i18n.t("operation"),
					editable: true,
					width: 200,
					validate: "required",
					disableClearable: true,
					valueOptions: ({ id }) => {
						const usedOperationIds = getApi()
							.getRows()
							.filter((r) => {
								if (r.operationId == null) return false;
								// Allow use operation of this row
								return id !== getApi().getRowId(r);
							})
							.map((r) => r.operationId);

						return operationOptions.filter((op) => !usedOperationIds.includes(op.operationId));
					},
					getOptionValue: (op) => op.operationId,
					getOptionLabel: (op) => concatWithPipe(op.operationNo, op.operationDescription),
				}),
				autoCompleteSingleSelectColumn({
					field: "materialOperationOperationId",
					headerName: i18n.t("material_operation"),
					editable: true,
					width: 200,
					validate: "required",
					valueOptions: ({ id }) =>
						filterNulls(
							getApi()
								.getRows()
								.filter((r) => r.operationId != null)
								// Show self row first.
								.sort((a, b) => {
									if (getApi().getRowId(a) === id) return -1;
									if (getApi().getRowId(b) === id) return 1;
									return a.position - b.position;
								}),
						),
					getOptionValue: ({ operationId }) => operationId,
					getOptionLabel: ({ operationId }) => {
						const operation = operationsByIds[operationId];
						if (operation == null) return "";
						return concatWithPipe(operation.operationNo, operation.operationDescription);
					},
				}),
				partIsConfigurable && {
					type: "boolean",
					field: "hasConfigurationRule",
					headerName: i18n.t("configuration_rule"),
					width: 150,
					valueGetter: (_, row) => !isNullOrBlank(row.configuratorTransformationScript),
					renderCell: (params) => (
						<HorizontalBox alignItems={"center"} gap={0.5}>
							<GridBooleanCell {...params} />
							<AavoIconButton
								icon={faLaptopCode}
								size={"small"}
								tooltip={i18n.t("configuration_rule")}
								onClick={() => {
									openTransformationScriptEditor(params.row);
								}}
							/>
						</HorizontalBox>
					),
				},
				floatColumn({
					field: "setUpTime",
					headerName: i18n.t("set_up_time_hours"),
					editable: true,
					width: 150,
				}),
				floatColumn({
					field: "workingTime",
					headerName: i18n.t("working_time_hours"),
					editable: true,
					width: 150,
				}),
			]}
			add={
				editable ?
					{
						type: "enabled",
						action: () => ({
							partRevisionId: partRevisionId,
							position: getDefaultPosition(getApi()),
						}),
					}
				:	{ type: "hidden" }
			}
			save={
				editable ?
					{
						type: "enabled",
						action: async ({ items }) => {
							return await SitePartRevisionOperationsDataGridApi.save({
								partRevisionId: partRevisionId,
								partRevisionOperations: items,
							});
						},
					}
				:	{ type: "hidden" }
			}
			remove={
				editable ?
					{
						type: "enabled",
						action: async ({ items }) => {
							return await SitePartRevisionOperationsDataGridApi.delete_({
								partRevisionId: partRevisionId,
								partRevisionOperations: items,
							});
						},
					}
				:	{ type: "hidden" }
			}
			actionBarComponents={
				<>
					{partIsConfigurable && (
						<AsyncButton
							variant={"outlined"}
							icon={faLaptopCode}
							label={i18n.t("configuration_rule")}
							disabled={!onlySelectedRow}
							onClick={async () => {
								if (!onlySelectedRow) return;
								// Cannot use onlySelectedRow directly, because it hasn't local modifications
								const internalRow = getApi().getRow(onlySelectedRow.partRevisionOperationId);
								if (internalRow == null) return;
								await openTransformationScriptEditor(internalRow);
							}}
						/>
					)}
				</>
			}
			{...dataGridProps}
		/>
	);

	async function openTransformationScriptEditor(row: PartRevisionOperationView) {
		const formResult = await showFormDialog<{ script: string }>({
			title: i18n.t("configuration_rule"),
			size: "xl",
			defaultValues: {
				script: row.configuratorTransformationScript,
			},
			// confirmCloseIfEdited: true,
			content: ({ control }) => (
				<FormConfiguratorLuaEditor
					control={control}
					name={"script"}
					disabled={!editable}
					productFamilyVersionId={partRevision.configurationProductFamilyVersionId}
					catalogPartRevisionId={partRevision.catalogPartRevisionId}
				/>
			),
		});
		if (formResult === undefined) return;

		if (crudDataGridApiRef.current == null) throw Error("crudDataGridApiRef is null");
		crudDataGridApiRef.current.updateRows([
			{
				...row,
				configuratorTransformationScript: formResult.script,
			},
		]);
	}

	function getDefaultPosition(api: AavoDataGridApi<PartRevisionOperationView>): number {
		const currentPositions = api.getRows().map((r) => r.position);
		if (currentPositions.length === 0) return 1;
		return Math.max(...currentPositions) + 1;
	}
};
