import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { ConfiguratorFieldComponent } from "src/components/views/erp/configurator/configuratorForm/components/ConfiguratorFieldComponent.tsx";
import { SubConfiguratorComponent } from "./SubConfiguratorComponent";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { SubConfiguratorListComponent } from "src/components/views/erp/configurator/configuratorForm/components/subConfiguratorList/SubConfiguratorListComponent.tsx";
import React, { useEffect, useState } from "react";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { setComponentCurrentValue } from "src/components/views/erp/configurator/configuratorForm/configuratorFormUtils.tsx";
import { Box } from "@mui/material";
import { NonLayoutTransformedConfigurationComponent } from "src/components/views/erp/configurator/configuratorForm/components/types/NonLayoutTransformedConfigurationComponent.tsx";
import { mergeSx } from "src/utils/styles.ts";

export interface ConfiguratorComponentProps {
	apiRef: React.RefCallback<ConfiguratorComponentApi>;
	component: NonLayoutTransformedConfigurationComponent;
	onSubmit: (value: ConfigurationPropertyValue) => Promise<unknown>;
	configuratorType: PartConfiguratorType;
	propertyValues: ConfigurationPropertyValues;
}

export interface ConfiguratorComponentApi {
	setValue: (value: ConfigurationPropertyValue) => void;
}

export const ConfiguratorComponent = (props: ConfiguratorComponentProps) => {
	const { component } = props;
	if (!component.visible) return null;

	return (
		<LayoutWrapper {...props}>
			<ConfiguratorComponentInner {...props} />
		</LayoutWrapper>
	);
};

const LayoutWrapper = ({ children, component }: ConfiguratorComponentProps & React.PropsWithChildren) => {
	const layout = component.layout;
	return (
		<Box
			sx={mergeSx(
				layout.spanAllColumns ?
					{ gridColumn: "1 / -1" }
				:	{
						gridColumnStart: layout.placeOnNewRow ? `1` : "auto",
						gridColumnEnd: `span ${layout.columnSpan ?? 1}`,
					},
			)}
		>
			{children}
		</Box>
	);
};

const ConfiguratorComponentInner = (props: ConfiguratorComponentProps) => {
	const { apiRef, component, onSubmit: onSubmitProp, configuratorType, propertyValues } = props;

	const [componentValue, setComponentValue] = useState<ConfigurationPropertyValue>(component.currentValue);

	useEffect(() => {
		apiRef({
			setValue: setComponentValue,
		});
	}, [setComponentValue, apiRef]);

	const onSubmit = async (newValue: ConfigurationPropertyValue) => {
		setComponentValue(newValue);
		await onSubmitProp(newValue);
	};

	const componentWithLiveValue = setComponentCurrentValue(component, componentValue);

	switch (componentWithLiveValue.type) {
		case "field":
			return (
				<ConfiguratorFieldComponent
					field={componentWithLiveValue}
					onChange={setComponentValue}
					onSubmit={onSubmit}
				/>
			);
		case "subConfigurator":
			return (
				<SubConfiguratorComponent
					component={componentWithLiveValue}
					onSubmit={onSubmit}
					parentPropertyValues={propertyValues}
					parentConfiguratorType={configuratorType}
				/>
			);
		case "subConfiguratorList":
			return (
				<SubConfiguratorListComponent
					component={componentWithLiveValue}
					onSubmit={onSubmit}
					parentPropertyValues={propertyValues}
					parentConfiguratorType={configuratorType}
				/>
			);
	}
};
