// This file is automatically generated. Do not edit manually.

import { Calendar } from "src/api/generated/postgres/db/types/public_/tables/calendar";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartDataGridApi {
	export async function getControlChartsInChartGroup(args: {
		chartGroupId: number;
		searchQuery?: string;
		showDisabledCharts?: boolean;
	}): Promise<ControlChartDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/getControlChartsInGroup",
			method: "POST",
			data: {
				chartGroupId: args.chartGroupId,
				searchQuery: args.searchQuery,
				showDisabledCharts: args.showDisabledCharts,
			},
		});
		return response as ControlChartDataGridApi_GridData;
	}

	export async function getControlChartsForResourceItem(args: {
		resourceItemId: number;
		searchQuery?: string;
		showDisabledCharts?: boolean;
	}): Promise<ControlChartDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChart/getControlChartsForResourceItem",
			method: "POST",
			data: {
				resourceItemId: args.resourceItemId,
				searchQuery: args.searchQuery,
				showDisabledCharts: args.showDisabledCharts,
			},
		});
		return response as ControlChartDataGridApi_GridData;
	}
}

export interface ControlChartDataGridApi_GridData {
	calendarOptions: Array<Calendar>;
	charts: Array<ControlChart>;
}
