// This file is automatically generated. Do not edit manually.

import { ChartGroup } from "src/api/generated/postgres/db/types/public_/tables/chartGroup";
import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ControlChartGroupEditApi {
	export async function save(args: { chartGroups: Array<ChartGroup> }): Promise<Array<ChartGroupView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartGroup/save",
			method: "POST",
			data: {
				chartGroups: args.chartGroups,
			},
		});
		return response as Array<ChartGroupView>;
	}

	export async function delete_(args: { chartGroupIds: Array<number> }): Promise<Array<void>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlChartGroup/delete",
			method: "POST",
			data: {
				chartGroupIds: args.chartGroupIds,
			},
		});
		return response as Array<void>;
	}
}
